.quote {
    padding: 32px 20px;
    @include medium {
        padding: 48px 64px 48px 280px;
    }
    @include xlarge {
        padding: 48px 120px;
    }
    &.align-left {
        .quote__text {
            text-align: left;
        }
    }
    &.align-right {
        .quote__text {
            text-align: right;
            @include xlarge {
                padding: 26px 0px 0 280px;
            }
        }
    }
    &.align-center {
        .quote__text {
            text-align: center;
            @include xlarge {
                padding: 26px 160px 0 300px;
            }
        }
    }
    &::before {
        content: '';
        background-image: url('../images/quote.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70px;
        position: absolute;
        left: 12px;
        top: 30px;
        width: 70px;
        height: 44px;
        @include medium {
            top: 56px;
            left: 70px;
            background-size: 155px;
            width: 155px;
            height: 95px;
        }
        @include xlarge {
            display: none;
        }
    }
    // &::after {
    //     content: '';
    //     position: absolute;
    //     width: 75%;
    //     height: 4px;
    //     background-color: $primary-color-dark;
    //     bottom: 48px;
    //     right: 0;
    //     @include medium {
    //         bottom: 70px;
    //     }
    //     @include xlarge {
    //         bottom: 50px;
    //         width: 34%;
    //     }
    // }
    &__text {
        color: $primary-color-dark;
        font-size: 1.5rem;
        font-weight: 400;
        margin-left: 80px;
        @include medium {
            font-size: 2.25rem;
            margin-left: 0;
        }
        @include xlarge {
            font-size: 2.5rem;
            padding: 26px 220px 0;
        }
        &::before {
            @include xlarge {
                content: '';
                background-image: url('../images/quote.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 155px;
                position: absolute;
                left: 0;
                top: 0;
                width: 155px;
                height: 95px;
            }
        }
    }
    &__author-container {
        text-align: right;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 24px;
    }
    &__author_name {
        font-size: 1.5rem;
        color: $primary-color-dark;
        margin: 0;
        @include medium {
            font-size: 1.75rem;
        }
        @include xlarge {
            font-size: 2rem;
        }
    }
    &__author_title {
        font-size: .875rem;
        text-transform: uppercase;
        margin: 0;
        color: $primary-color-dark;
        max-width: 300px;
        @include medium {
            font-size: 1rem;
            max-width: 400px;
        }
        @include xlarge {
            font-size: 1.125rem;
            max-width: 500px;
        }
    }
}

.row, .split {
    .quote {
        @include medium {
            padding: 50px 12px 48px 108px;
        }
        @include xlarge {
            padding: 24px;
        }
        &::before {
            @include medium {
                top: 24px;
                left: 20px;
                background-size: 75px;
                width: 75px;
                height: 95px;
            }
            @include large {
                background-size: 70px;
                width: 70px;
            }
        }
        &.align-left {
            .quote__text {
                @include xlarge {
                    padding: 26px 16px 0 90px;
                }
            }
        }
        &.align-right {
            .quote__text {
                @include xlarge {
                    padding: 26px 16px 0 90px;
                }
            }
        }
        &.align-center {
            .quote__text {
                @include xlarge {
                    padding: 26px 16px 0 90px;
                }
            }
        }
        &__text {
            @include medium {
                font-size: 1.75rem;
                line-height: 1.3;
            }
            @include large {
                font-size: 1.5rem;
            }
            @include xlarge {
                padding: 26px 16px 0 90px;
                font-size: 1.75rem;
            }
            &::before {
                @include xlarge {
                    background-size: 75px;
                    width: 75px;
                }
            }
        }
        &__author_name {
            @include large {
                font-size: 1.5rem;
            }
            @include xlarge {
                font-size: 1.75rem;
            }
        }
        &__author_title {
            @include xlarge {
                font-size: 1rem;
            }
        }
    }
}