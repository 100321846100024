.get-involved-callout {
    padding: 32px 12px;
    overflow: hidden;
    @include medium {
        padding: 40px 0;
    }
    @include xlarge {
        padding: 80px 20px;
    }
    .background-element {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 450px;
        @include medium {
            top: 500px;
        }
        @media (min-width: 1400px) {
            top: 50%;
        }
        &.light {
            background-color: $tan-light;
        }
        &.dark {
            background-color: $tan-dark;
        }
    }
    .involved-container {
        max-width: 1600px;
        margin: 0 auto;
        text-align: center;
        > h2 {
            margin-bottom: 24px;
            @include medium {
                margin-bottom: 40px;
            }
            @include xlarge {
                margin-bottom: 60px;
            }
        }
        .ctas {
            text-align: left;
            max-width: 365px;
            margin: 0 auto 48px;
            @include medium {
                display: table;
                max-width: 900px;
                border-collapse: separate;
                border-spacing: 40px 0;
                width: 100%;
                margin-bottom: 48px;
                min-height: 450px;
            }
            @include large {
                min-height: 475px;
            }
            @include xlarge {
                min-height: 500px;
            }
            @media (min-width: 1400px) {
                width: 50%;
                float: left;
                min-height: 525px;
            }
            .cta {
                border-radius: 20px;
                background-color: $white;
                overflow: hidden;
                display: block;
                min-height: 400px;
                box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
                max-width: none;
                text-align: left;
                margin: 0 0 32px;
                @include medium {
                    display: table-cell;
                    margin-bottom: 0;
                    width: 50%;
                    vertical-align: top;
                }
                .header {
                    max-height: 175px;
                    height: 44vw;
                    background-color: $blue;
                    display: flex;
                    align-items: center;
                    padding: 0 36px;
                    transition: background-color $trans-med ease;
                    @include medium {
                        max-height: 185px;
                        height: 21vw;
                        padding: 0 40px;
                    }
                    @media (min-width: 1400px) {
                        height: 10vw;
                        max-height: 160px;
                    }
                    h3 {
                        font-size: 1.75rem;
                        color: $white;
                        margin: 0;
                        padding: 0;
                        @include medium {
                            font-size: 2rem;
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .header-image {
                        width: 100%;
                        height: 100%;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
                h3 {
                    font-size: 1.75rem;
                    color: $black;
                    margin-bottom: 16px;
                    margin-top: 24px;
                    padding: 0 36px;
                    @include medium {
                        font-size: 2rem;
                        padding: 0 40px;
                    }
                    + .content {
                        padding-top: 0;
                    }
                }
                .content {
                    background-color: $white;
                    padding: 30px 36px 75px;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    @include medium {
                        padding: 32px 40px 75px;
                    }
                    h2, h3, h4, h5, h6 {
                        + p {
                            margin-top: 8px;
                            -webkit-line-clamp: 5;
                            max-height: 7.5em;
                            line-height: 1.5;
                        }
                    }
                    p {
                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 10;
                        max-height: 15em;
                        line-height: 1.5;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        visibility: visible;
                    }
                }
                &:first-child {
                    margin-bottom: 45px;
                    @include medium {
                        margin-bottom: 0;
                    }
                }
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    width: 56px;
                    height: 56px;
                    border-top-left-radius: 20px;
                    background-size: 24px;
                    background-color: $orange;
                    background-image: url('../images/skinny-arrow-white.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: background-color $trans-med ease;
                }
                &.image {
                    height: auto;
                    max-height: none;
                    .header {
                        padding: 0;
                        @include medium {
                            padding: 0;
                        }
                    }
                }
                &:hover, &:focus {
                    .header {
                        background-color: $blue-dark;
                    }
                    &::after, &::after {
                        background-color: $orange-dark;
                    }
                }
            }
        }
        .events {
            text-align: center;
            @include medium {
                padding: 0 40px;
            }
            @media (min-width: 1400px) {
                padding: 0 40px 0 0;
                float: right;
                width: 50%;
            }
            .event {
                border-bottom-right-radius: 20px;
                border-top-left-radius: 20px;
                background-color: $white;
                border-width: 2px;
                border-style: solid;
                border-color: $red;
                margin-bottom: 16px;
                min-height: 350px;
                overflow: hidden;
                display: block;
                text-align: left;
                transition: border-color $trans-med ease;
                @include medium {
                    min-height: 150px;
                    display: table;
                    width: 100%;
                }
                @media (min-width: 1400px) {
                    margin-bottom: 12px;
                }
                .date {
                    display: block;
                    height: 100%;
                    background-color: $red;
                    border-bottom-right-radius: 20px;
                    text-align: center;
                    padding: 16px;
                    transition: background-color $trans-med ease;
                    @include medium {
                        display: table-cell;
                        vertical-align: middle;
                        width: 110px;
                    }
                    .month {
                        font-size: 1.1875rem;
                        display: block;
                        color: $white;
                        text-transform: uppercase;
                        font-weight: $font-weight-bold;
                        @include medium {
                            font-size: 1.3125rem;
                        }
                    }
                    .day {
                        font-size: 2.625rem;
                        display: block;
                        color: $white;
                        font-weight: $font-weight-bold;
                        @include medium {
                            font-size: 3rem;
                        }
                    }
                }
                .event-details {
                    padding: 24px 24px 48px;
                    @include medium {
                        display: table-cell;
                        vertical-align: middle;
                        padding: 16px 90px 16px 40px;
                    }
                    @media (min-width: 1400px) {
                        padding: 14px 90px 18px 40px;
                    }
                    .category-container {
                        a {
                            display: inline-block;
                            border-radius: 14px;
                            border: 1px solid $tan;
                            font-size: .75rem;
                            margin: 0 12px 12px 0;
                            padding: 2px 10px;
                            transition: background-color $trans-med ease;
                            &:hover, &:focus {
                                background-color: $tan;
                            }
                        }
                    }
                    .event-link {
                        display: block;
                    }
                    .title {
                        margin-top: 0;
                        font-size: 1.25rem;
                        margin-bottom: 12px;
                        @media (min-width: 1400px) {
                            margin-bottom: 8px;
                        }
                    }
                    .arrow {
                        background-image: url('../images/skinny-arrow.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 100%;
                        transition: background-color $trans-med ease;
                        position: absolute;
                        bottom: 14px;
                        right: 16px;
                        width: 34px;
                        height: 26px;
                        opacity: 0;
                        transition: opacity $trans-med ease;
                        @include medium {
                            right: 24px;
                            bottom: auto;
                            width: 40px;
                            height: 31px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                &.hover {
                    border-color: $orange;
                    .date {
                        background-color: $orange;
                    }
                    .event-details {
                        .arrow {
                            opacity: 1;
                        }
                    }
                }
            }
            .button {
                margin-top: 10px;
            }
        }
    }
}