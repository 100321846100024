.bio-header {
    background-image: linear-gradient(to right, #EAE4D7, #F9F7F3);
    padding: 40px 30px 36px;
    margin-bottom: 40px;
    @include medium {
        padding: 36px 75px 32px;
        margin-bottom: 32px;
    }
    @include xlarge {
        padding: 46px 75px 42px;
        margin-bottom: 60px;
    }
    h1 {
        max-width: 1520px;
        margin: 0 auto;
        + .role {
            max-width: 1520px;
            margin: 10px auto 0;
            font-size: 1.125rem;
            @include medium {
                font-size: 1.25rem;
                margin-top: 12px;
            }
            @include xlarge {
                font-size: 1.75rem;
                margin-top: 20px;
            }
            &:empty {
                display: none;
            }
        }
    }
    
}
.bio {
    max-width: 1350px;
    margin: 0 auto 50px;
    padding: 0 12px;
    @include medium {
        margin-bottom: 60px;
        padding: 0 40px;
    }
    @include xlarge {
        padding: 0 75px;
    }
    .bio-pic {
        text-align: center;
        margin-bottom: 38px;
        @include medium {
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 0;
        }
        img {
            width: 189px;
            height: 189px;
            border-radius: 15px;
            @include xlarge {
                width: 262px;
                height: 262px;
            }
        }
    }
    .bio-text {
        padding: 0 20px;
        @include medium {
            padding: 0;
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
            width: calc(100% - 234px);
        }
        @include xlarge {
            width: calc(100% - 310px);
        }
    }
}
.tabs {
    max-width: 1520px;
    margin: 0 auto 48px;
    padding: 0 12px;
    @include medium {
        margin-bottom: 60px;
        padding: 0 40px;
    }
    @include xlarge {
        padding: 0 75px;
        margin-bottom: 75px;
    }
    .tabs-labels {
        display: none;
        @include large {
            display: block;
            list-style-type: none;
            text-align: center;
        }
        @include xlarge {
            margin: 0 -70px 0 -70px;
        }
        li {
            display: inline-block;
            a {
                font-family: minerva-modern, sans-serif;
                display: inline-block;
                font-size: 1.5rem;
                font-weight: 400;
                padding: 30px 30px 27px;
                text-transform: uppercase;
                transition: background-color $trans-med ease, color $trans-med ease;
                @include xlarge {
                    font-size: 1.75rem;
                    padding: 40px 48px 37px;
                }
                &:hover {
                    color: $red;
                    background-color: rgba(126, 74, 72, .04);
                }
                &.active {
                    color: $red;
                    background-color: rgba(126, 74, 72, .04);
                    border-bottom: 3px solid $red;
                    padding-bottom: 24px;
                    @include xlarge {
                        border-bottom: 4px solid $red;
                        padding-bottom: 33px;
                    }
                }
            }
        }
    }
    .tabs-contents {
        padding: 0 20px;
        .tab-content-toggle {
            display: block;
            height: 70px;
            padding: 25px;
            text-align: center;
            border-top: 1px solid rgba(72, 72, 72, .2);
            color: $red;
            font-family: minerva-modern, sans-serif;
            font-size: 1.125rem;
            font-weight: 400;
            text-transform: uppercase;
            @include large {
                display: none;
            }
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
                background-image: url('../images/caret-red.svg');
                background-repeat: no-repeat;
                background-position: center;
                height: 20px;
                width: 20px;
                transition: transform $trans-med ease, top $trans-med ease;
            }
            &:last-of-type {
                border-bottom: 1px solid rgba(72, 72, 72, .2);
            }
            &.active {
                background-color: rgba(126, 74, 72, .04);
                border-bottom: 1px solid rgba(72, 72, 72, .2);
                &::after {
                    transform: rotate(180deg);
                    top: calc(50% - 10px);
                }
            }
        }
        .tab-content {
            max-height: 0;
            overflow: hidden;
            transition: max-height $trans-med ease, padding $trans-med ease;
            padding: 0 38px;
            @include large {
                max-width: 75%;
                margin: 0 auto;
                max-height: none;
                display: none;
            }
            @include xlarge {
                max-width: 1276px;
            }
            &.active {
                max-height: 2500px;
                padding: 24px 38px 0;
                @include large {
                    display: block;
                    padding: 32px 38px 0;
                }
                @include xlarge {
                    padding-top: 54px;
                }
            }
            p {
                margin: 0;
            }
            h1, h2, h3, h4, h5, h6 {
                margin-bottom: 24px;
            }
            a {
                color: $blue;
                text-decoration: underline;
            }
            ul {
                list-style-type: none;
                @include xlarge {
                    column-count: 2;
                    column-gap: 65px;
                }
                li {
                    margin-bottom: 24px;
                    @include xlarge {
                        margin-bottom: 36px;
                    }
                    &::before {
                        content: '';
                        height: 15px;
                        width: 15px;
                        border-radius: 50%;
                        background-color: $blue;
                        position: absolute;
                        top: 1px;
                        left: -28px;
                    }
                }
            }
        }
    }
}
.event-pub-callout {
    max-width: 1640px;
    margin: 0 auto 48px;
    @include medium {
        margin-bottom: 80px;
    }
    @include xlarge {
        padding: 0 60px;
        margin-bottom: 112px;
    }
    .past-events {
        padding: 0 12px;
        margin-bottom: 40px;
        @include medium {
            padding: 0 40px;
            margin-bottom: 60px;
        }
        @include xlarge {
            padding: 0;
            display: inline-block;
            vertical-align: top;
            width: 38%;
            margin-bottom: 0;
        }
        .past-event {
            display: block;
            padding: 30px 32px 68px;
            border-radius: 20px;
            background-color: $green;
            box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
            margin-bottom: 24px;
            overflow: hidden;
            text-decoration: none;
            transition: box-shadow $trans-med ease, background-color $trans-med ease;
            @include medium {
                padding: 50px 70px 90px;
                border-top-right-radius: 0;
                border-bottom-left-radius: 0;
                margin-bottom: 40px;
            }
            @include xlarge {
                padding-bottom: 100px;
            }
            .title {
                color: $white;
                font-size: 1.625rem;
                margin-bottom: 12px;
                @include medium {
                    font-size: 2.25rem;
                }
                @include xlarge {
                    margin-bottom: 20px;
                }
            }
            .date {
                display: block;
                color: $white;
                font-size: 1rem;
                font-weight: $font-weight-bold;
                text-transform: uppercase;
                margin-bottom: 30px;
                @include medium {
                    margin-bottom: 24px;
                }
            }
            .excerpt {
                font-size: 1.125rem;
                color: $white;
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                max-height: 4.5em;
                line-height: 1.5;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                visibility: visible;
                p {
                    color: $white;
                }
            }
            &:after {
                content: '';
                background-image: url('../images/skinny-arrow.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 22px;
                background-color: $white;
                position: absolute;
                right: 0;
                bottom: 0;
                width: 57px;
                height: 55px;
                border-top-left-radius: 20px;
                @include medium {
                    width: 78px;
                    height: 77px;
                    background-size: 30px;
                }
                @include xlarge {
                    width: 84px;
                    height: 82px;
                    background-size: 32px;
                }
            }
            &:hover, &:focus {
                background-color: $green-dark;
                box-shadow: 0 3px 20px rgba(0, 0, 0, .4);
            }
        }
        .button {
            color: $white;
            text-decoration: none;
        }
    }
    .publications {
        padding: 0 12px;
        @include medium {
            padding: 0 40px;
        }
        @include xlarge {
            padding: 0;
            display: inline-block;
            width: 56%;
            margin-left: 5%;
            vertical-align: top;
        }
        h2 {
            margin-bottom: 24px;
            @include medium {
                margin-bottom: 48px;
            }
            @include xlarge {
                margin-bottom: 36px;
            }
        }
        .pub {
            margin-bottom: 44px;
            @include medium {
                margin-bottom: 64px;
            }
            @include xlarge {
                margin-bottom: 52px;
            }
            .icon {
                margin-right: 12px;
                width: 45px;
                height: 48px;
                position: absolute;
                left: 0;
                top: 0;
                @include medium {
                    width: 57px;
                    height: 62px;
                }
                svg {
                    width: 100%;
                }
            }
            .title {
                padding-left: 60px;
                font-size: 1.25rem;
                margin-bottom: 18px;
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                max-height: 9.1em;
                line-height: 1.3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                visibility: visible;
                @include medium {
                    font-size: 1.5rem;
                    padding-left: 105px;
                    margin-bottom: 32px;
                    -webkit-line-clamp: 4;
                    max-height: 5.2em;
                }
                @include xlarge {
                    font-size: 1.625rem;
                    margin-bottom: 20px;
                    -webkit-line-clamp: 3;
                    max-height: 3.9em;
                }
            }
            .details {
                padding-left: 60px;
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 7;
                max-height: 9.1em;
                line-height: 1.3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                visibility: visible;
                margin: 0;
                @include medium {
                    font-size: 1.125rem;
                    padding-left: 105px;
                    -webkit-line-clamp: 4;
                    max-height: 5.2em;
                }
                @include xlarge {
                    font-size: 1.25rem;
                    -webkit-line-clamp: 3;
                    max-height: 3.9em;
                }
            }
            .button {
                margin-top: 30px;
                margin-left: 60px;
                color: #fff;
                text-decoration: none;
                @include medium {
                    margin-top: 40px;
                    margin-left: 105px;
                }
            }
        }
    }
}

.quote-callout {
    padding: 32px 32px 88px 32px;
    @include medium {
        padding: 56px 64px 120px 205px;
    }
    @include large {
        padding: 56px 64px 120px 280px;
    }
    @include xlarge {
        padding: 44px 60px 104px;
    }
    &::before {
        content: '';
        background-image: url('../images/quote.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70px;
        position: absolute;
        left: 50%;
        top: 40px;
        width: 70px;
        height: 44px;
        transform: translateX(-50%);
        @include medium {
            top: 56px;
            left: 50px;
            background-size: 125px;
            width: 125px;
            height: 95px;
            transform: none;
        }
        @include large {
            left: 70px;
            background-size: 155px;
            width: 155px;
        }
        @include xlarge {
            display: none;
        }
    }
    &::after {
        content: '';
        position: absolute;
        width: 75%;
        height: 4px;
        background-color: $white;
        bottom: 48px;
        right: 0;
        @include medium {
            bottom: 70px;
        }
        @include xlarge {
            bottom: 50px;
            width: 34%;
        }
    }
    .quote {
        color: $white;
        font-size: 1.5rem;
        font-family: minerva-modern, sans-serif;
        font-weight: 400;
        margin-left: 100px;
        padding: 68px 12px 24px;
        margin: 0;
        @include medium {
            font-size: 2.25rem;
            margin-left: 0;
            padding: 48px 16px 48px;
        }
        @include xlarge {
            font-size: 2.5rem;
            max-width: 1700px;
            margin: 0 auto;
            padding: 26px 220px 0;
        }
        &::before {
            display: none;
            @include xlarge {
                display: block;
                content: '';
                background-image: url('../images/quote.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 155px;
                position: absolute;
                left: 0;
                top: 0;
                width: 155px;
                height: 95px;
            }
        }
    }
    .author {
        text-align: right;
        margin-top: 16px;
        @include xlarge {
            max-width: 1260px;
            margin: 24px auto 0;
        }
        .name {
            font-size: 1.5rem;
            color: $white;
            @include medium {
                font-size: 1.75rem;
            }
            @include xlarge {
                font-size: 2rem;
            }
        }
        .desc {
            font-size: .875rem;
            text-transform: uppercase;
            margin-top: 8px;
            color: $white;
            display: inline-block;
            max-width: 300px;
            @include medium {
                font-size: 1rem;
                max-width: 400px;
            }
            @include xlarge {
                font-size: 1.125rem;
                max-width: 500px;
            }
        }
    }
    &.yellow {
        background-color: $orange;
    }
    &.blue {
        background-color: $blue;
    }
    &.green {
        background-color: $green;
    }
    &.red {
        background-color: $red;
    }
    &.off-white {
        background-color: $tan-light;
        &::before {
            background-image: url('../images/quote-black.svg');
        }
        &::after {
            background-color: $black;
        }
        .quote {
            color: $black;
            &::before {
                @include xlarge {
                    background-image: url('../images/quote-black.svg');
                }
            }
        }
        .author {
            .name {
                color: $black;
            }
            .desc {
                color: $black;
            }
        }
    }
    &.gray-light {
        background-color: $tan;
        &::before {
            background-image: url('../images/quote-black.svg');
        }
        &::after {
            background-color: $black;
        }
        .quote {
            color: $black;
            &::before {
                @include xlarge {
                    background-image: url('../images/quote-black.svg');
                }
            }
        }
        .author {
            .name {
                color: $black;
            }
            .desc {
                color: $black;
            }
        }
    }
}