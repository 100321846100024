/* ignition72 Base */
*,
* * {
	margin:0;
	padding:0;
	position:relative;
	box-sizing: border-box;
}

p {
	font-size: 1rem;
    margin-bottom: 12px;
    line-height: 1.33;
    @include large {
        font-size: 1.125rem;
        margin-bottom: 16px;
        line-height: 1.5;
    }
}

/*	ensure HTML5 elements are handled as block level on older browsers */
footer,
header,
nav,
main,
section {
	display: block;
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


html, body {
    height: 100%;
    width: 100%;
}

.button {
    display: inline-block;
    background-color: $primary-color;
    text-decoration: none;
    color: $white;
    font-weight: $font-weight-semi;
    text-align: center;
    border-radius: 4px;
    padding: 8px 20px;
    &.button-color--0061AA {
        background-color: #0061AA;
    }
    &.button-color--F5F6F7 {
        background-color: #F5F6F7;
        color: $black;
    }
    &.button-color--000000 {
        background-color: #000000;
    }
    &.button-color--5E6366 {
        background-color: #5E6366;
    }
}

.link-button {
    &.left {
        text-align: left;
    }
    &.align-center {
        text-align: center;
    }
    &.align-right {
        text-align: right;
    }
}

.hidden {
    display: none;
}

.page-container {
    background-color: $white;
    padding: 24px 0;
    > .page-width {
        width: calc(100% - 40px);
        margin: 0 auto 40px;
        max-width: 1600px;
        @include medium {
            width: calc(100% - 80px);
        }
        @include xlarge {
            width: calc(100% - 120px);
            margin-bottom: 60px;
        }
        @include xxlarge {
            margin-bottom: 60px;
        }
    }
    > .container--vertical.full-width, > .container--horizontal.full-width {
        margin-bottom: 40px;
        @include xlarge {
            margin-bottom: 60px;
        }
        @include xxlarge {
            margin-bottom: 60px;
        }
        + .container--vertical.full-width, + .container--horizontal.full-width {
            margin-top: -40px;
            @include xlarge {
                margin-top: -60px;
            }
            @include xxlarge {
                margin-top: -60px;
            }
        }
    }
    > .row.full-width {
        margin-bottom: 40px;
        @include xlarge {
            margin-bottom: 60px;
        }
        @include xxlarge {
            margin-bottom: 60px;
        }
        + .row.full-width {
            margin-top: -40px;
            @include xlarge {
                margin-top: -60px;
            }
            @include xxlarge {
                margin-top: -60px;
            }
        }
    }
    > .full-width {
        margin-bottom: 40px;
        @include xlarge {
            margin-bottom: 60px;
        }
        @include xxlarge {
            margin-bottom: 60px;
        }
    }
}

.container.page-width {
    width: calc(100% - 40px);
    margin: 0 auto 40px;
    max-width: 1600px;
    @include medium {
        width: calc(100% - 80px);
    }
    @include xlarge {
        width: calc(100% - 120px);
        margin-bottom: 60px;
    }
    @include xxlarge {
        margin-bottom: 60px;
    }
}


// HEADINGS //
h1 {
    font-size: 2.25rem;
    @include medium {
        font-size: 2.625rem;
    }
    @include xlarge {
        font-size: 3.25rem;
    }
}

h2 {
    font-size: 2rem;
    @include medium {
        font-size: 2.375rem;
    }
    @include xlarge {
        font-size: 3rem;
    }
}

h3 {
    font-size: 1.75rem;
    @include medium {
        font-size: 2rem;
    }
    @include xlarge {
        font-size: 2.25rem;
    }
}

h4 {
    font-size: 1.625rem;
    @include medium {
        font-size: 1.875rem;
    }
    @include xlarge {
        font-size: 2.125rem;
    }
}

h5 {
    font-size: 1.5rem;
    @include medium {
        font-size: 1.625rem;
    }
    @include xlarge {
        font-size: 2rem;
    }
}

h6 {
    font-size: 1.33rem;
    @include medium {
        font-size: 1.5rem;
    }
    @include xlarge {
        font-size: 1.875rem;
    }
}

h1, h2, h3, h4, h5, h6 {
    margin-bottom: 12px;
    @include xlarge {
        margin-bottom: 16px;
    }
    &.text-color--0061AA {
        color: #0061AA;
    }
    &.text-color--F5F6F7 {
        color: #F5F6F7;
    }
    &.text-color--000000 {
        color: #000000;
    }
    &.text-color--5E6366 {
        color: #5E6366;
    }
    &.align-left {
        text-align: left;
    }
    &.align-center {
        text-align: center;
    }
    &.align-right {
        text-align: right;
    }
}

.wysiwyg {
    ul, ol {
        padding-left: 16px;
        margin-bottom: 20px;
        li {
            margin-bottom: 8px;
        }
    }
    ol {
        padding-left: 18px;
    }
}

.video, .wp-video {
    margin: 24px 0;
    .mejs-container {
        .mejs-inner {
            height: 100%;
            .mejs-layers {
                height: 100%;
            }
        }
    }
}

.popup-embed {
    @include large {
        margin-bottom: 18px;
    }
}

body.customize-support {
    padding-top: 46px;
    @media (min-width: 783px) {
        padding-top: 32px;
    }
}