.event-info {
    padding: 0 40px;
    margin-bottom: 40px;
    @include medium {
        margin-bottom: 60px;
    }
    @include xlarge {
        padding: 0 60px;
        max-width: 1640px;
        margin: 0 auto 75px;
    }
    .event-info-container {
        &.with-image {
            @media (min-width: 1400px) {
                width: 50%;
                display: inline-block;
                vertical-align: top;
            }
            @include xxlarge {
                padding-top: 24px;
            }
        }
        .event-type {
            a {
                font-size: .875rem;
                border-radius: 6rem;
                border: 1px solid $tan;
                margin-bottom: 6px;
                display: inline-block;
                padding: 1px 15px;
                text-transform: uppercase;
                transition: background-color .4s ease;
                &:last-of-type {
                    margin-bottom: 12px;
                    @include medium {
                        margin-bottom: 20px;
                    }
                    @include xlarge {
                        margin-bottom: 25px;
                    }
                }
                &:hover, &:focus {
                    background-color: $tan;
                }
            }
        }
        .tags {
            display: none;
        }
        .date-time-info {
            margin-bottom: 12px;
            @include medium {
                margin-bottom: 20px;
            }
            .rrule, .date, .time {
                font-size: 1rem;
                text-transform: uppercase;
                font-weight: $font-weight-bold;
                display: block;
                @include medium {
                    display: inline-block;
                    margin-right: 24px;
                }
            }
            .location {
                margin-top: 24px;
            }
        }
        .desc {
            @include medium {
                font-size: 1.125rem;
            }
            p {
                @include medium {
                    font-size: 1.125rem;
                }
            }
        }
    }
    .event-img {
        margin: 24px auto 0;
        text-align: center;
        @include medium {
            max-width: 925px;
            margin-top: 40px;
        }
        @media (min-width: 1400px) {
            width: 45%;
            max-width: none;
            display: inline-block;
            vertical-align: top;
            margin: 0;
            margin-left: 4%;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}