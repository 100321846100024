.background {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 32px 0;
    @include medium {
        padding: 40px 0;
    }
    @include xlarge {
        padding: 60px 0;
    }
    &.full-width {
        .background__inner-container {
            > *.page-width {
                width: calc(100% - 40px);
                margin: 0 auto 40px;
                @include medium {
                    width: calc(100% - 80px);
                }
                @include xlarge {
                    width: calc(100% - 120px);
                    margin: 0 auto 60px;
                    max-width: 1600px;
                }
                @include xxlarge {
                    margin-bottom: 60px;
                }
                &:last-child {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    &.background-color--0061AA {
        background-color: #0061AA;
        .background__inner-container {
            > .wysiwyg, > .paragraph .paragraph__title, > .paragraph .paragraph__text, 
            > .paragraph .paragraph__link, > .quote .quote__text, > .quote .quote__author_name, > .quote .quote__author_title {
                color: $white;
            }
            > .row {
                .wysiwyg, .paragraph .paragraph__title, .paragraph .paragraph__text, 
                .paragraph .paragraph__link, .quote .quote__text, .quote .quote__author_name, .quote .quote__author_title {
                    color: $white;
                }
            }
        }
    }
    &.background-color--F5F6F7 {
        background-color: #F5F6F7;
    }
    &__image-container {
        height: 100%;
        position: absolute;
        inset: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 110%;
            min-width: 110%;
            height: auto;
        }
    }
    &__inner-container {
        margin-bottom: 0;
        .split {
            margin-bottom: 0;
        }
        > *.page-width {
            width: 100%;
            margin: 0 auto 40px;
            @include xlarge {
                margin-bottom: 60px;
            }
            @include xxlarge {
                margin-bottom: 60px;
            }
            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }
    &.page-width {
        padding: 32px;
        @include medium {
            padding: 40px;
        }
        @include xlarge {
            padding: 60px;
        }
    }
}