.filter-wrapper {
    min-height: 100vh;
    overflow: hidden;
    .filter-sidebar {
        width: 100%;
        z-index: 1;
        left: 0;
        height: 100%;
        transition: left $trans-med ease, width $trans-med ease, height $trans-med ease;
        @include medium {
            width: 340px;
            margin-top: 25px;
            float: left;
        }
        @include xlarge {
            width: 370px;
        }
        .filter-toggle {
            background-color: $white;
            position: absolute;
            z-index: 2;
            height: 48px;
            font-size: 18px;
            padding: 11px 15px 12px;
            right: 0;
            top: 16px;
            border-radius: 8px 0px 0px 8px;
            transition: width $trans-med ease, right $trans-med ease, background-color $trans-med ease, box-shadow $trans-med ease;
            span {
                display: none;
                margin-right: 8px;
            }
            svg {
                width: 21px;
                height: 13px;
                transform: rotate(180deg);
                transition: transform $trans-med ease;
            }
        }
        .filter-container {
            background-color: $tan-light;
            width: 100%;
            box-shadow: 0 6px 20px rgba(106, 106, 106, .35);
            transition: box-shadow $trans-med ease;
            @include medium {
                margin-bottom: -10000px;
                padding-bottom: 10000px;
                border-radius: 0 8px 0 0;
            }
            form {
                padding: 85px 55px;
                @include medium {
                    padding: 50px 36px 125px;
                }
                @include xlarge {
                    padding: 50px 40px 125px;
                }
                h3 {
                    font-size: 1.875rem;
                    margin-bottom: 10px;
                    color: #0F3051;
                    font-family: lato, sans-serif;
                }
                .filter-item {
                    margin-bottom: 10px;
                    + h3 {
                        margin-top: 70px;
                    }
                }
                .filter-item input[type="checkbox"] {
                    opacity: 0;
                    position: absolute;
                    &:focus {
                        + label::before {
                            outline: -webkit-focus-ring-color auto 1px;
                        }
                    }
                }
                
                .filter-item label {
                    position: relative;
                    display: inline-block;
                    padding-left: 34px;
                    font-size: 1.125rem;
                    color: $black;
                }
                
                .filter-item label::before,
                .filter-item label::after {
                    position: absolute;
                    content: "";
                    display: inline-block;
                }
                
                .filter-item label::before{
                    height: 22px;
                    width: 22px;  
                    background-color: $white;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
                    border-radius: 4px;
                    left: 0px;
                    top: 2px;
                }
                
                /*Checkmark of the fake checkbox*/
                .filter-item label::after {
                    height: 10px;
                    width: 21px;
                    border-left: 2px solid #707070;
                    border-bottom: 2px solid #707070;
                    transform: rotate(-45deg);
                    left: 5px;
                    top: 4px;
                }
                
                .filter-item input[type="checkbox"] + label::after {
                    content: none;
                }
    
                .filter-item input[type="checkbox"]:checked + label {
                    font-weight: $font-weight-bold;
                }
                
                .filter-item input[type="checkbox"]:checked + label::after {
                    content: "";
                }
                .filter-children {
                    padding-left: 25px;
                    margin-top: 10px;
                }
                .filter-date-span {
                    display: block;
                    padding: 0 6px;
                    margin-bottom: 4px;
                }
                .filter-date-dropdown {
                    height: 40px;
                    max-width: 425px;
                    margin-bottom: 12px;
                    overflow: hidden;
                    transition: height $trans-med ease;
                    &.active {
                        height: 256px;
                        background-color: $white;
                        visibility: visible;
                        box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
                        border-radius: 4px;
                        .filter-date-title {
                            display: none;
                        }
                        .filter-select {
                            visibility: visible;
                            svg {
                                display: inline-block;
                            }
                        }
                    }
                    .filter-select {
                        height: 100%;
                        padding: 32px 0 68px;
                        visibility: hidden;
                        transition: visibility $trans-med ease;
                        .select {
                            height: 100%;
                            width: 50%;
                            float: left;
                            margin: 0;
                            text-align: center;
                            padding: 0;
                            border: 0;
                            font-size: 1rem;
                            outline: 0;
                            -moz-appearance: none; 
                            -webkit-appearance: none; 
                            appearance: none;
                            background: $white;
                            overflow: scroll;
                            list-style-type: none;
                            &:focus {
                                outline: -webkit-focus-ring-color auto 1px !important;
                            }
                            .option {
                                display: block;
                                padding: 4px 0;
                                margin: 0 14px;
                                &.selected {
                                    background: linear-gradient($white, $white);
                                    border-top: 2px solid $blue;
                                    border-bottom: 2px solid $blue;
                                    padding: 2px 0;
                                }
                                &:hover {
                                    background-color: #f4f4f4;
                                }
                            }
                        }
                        svg {
                            position: absolute;
                            width: 20px;
                            display: none;
                            &:first-of-type {
                                left: calc(25% - 10px);
                                top: 12px;
                                transform: rotate(180deg);
                            }
                            &:nth-of-type(3) {
                                left: calc(75% - 10px);
                                top: 12px;
                                transform: rotate(180deg);
                            }
                            &:nth-of-type(2) {
                                left: calc(25% - 10px);
                                bottom: 46px;
                            }
                            &:last-of-type {
                                left: calc(75% - 10px);
                                bottom: 46px;
                            }
                        }
                        .filter-ok, .filter-cancel {
                            position: absolute;
                            bottom: 0;
                            width: 50%;
                            left: 0;
                            height: 36px;
                            font-size: 1rem;
                            background-color: #fff;
                            transition: background-color $trans-med ease, color $trans-med ease;
                            box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
                            border: 0;
                            cursor: pointer;
                            border-top: 2px solid #f4f4f4;
                            &:hover {
                                background-color: $tan-light;
                            }
                            &:active {
                                background-color: $blue;
                                color: $white;
                            }
                        }
                        .filter-ok {
                            left: 50%;
                        }
                    }
                }
                .filter-date-title {
                    background-color: $white;
                    color: $blue;
                    width: 98%;
                    max-width: 425px;
                    display: block;
                    text-align: center;
                    padding: 5px;
                    border-radius: 4px;
                    box-shadow: 0 2px 4px rgba(0, 0, 0, .16);
                    margin-bottom: 12px;
                    transition: background-color $trans-med ease;
                    &:hover {
                        background-color: $tan-light;
                    }
                }
                .button {
                    margin: 75px auto 125px;
                    display: table;
                    cursor: pointer;
                }
                .clear-filter-container {
                    text-align: center;
                    display: block;
                    max-width: 425px;
                    width: 98%;
                    padding: 5px;
                    margin-top: 32px;
                    .filter-clear {
                        color: $blue;
                        padding-right: 24px;
                        background-image: url('../images/arrow-blue.svg');
                        background-repeat: no-repeat;
                        background-position: right center;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    .featured-filter-section {
        padding: 35px 12px 12px;
        transition: padding $trans-med ease;
        @include medium {
            padding: 0 40px 12px 375px;
            margin-top: 25px;
        }
        @include xlarge {
            padding: 0 60px 12px 410px;
        }
        .featured-filter-container {
            max-width: 1300px;
            margin: 0 auto;
            @include medium {
                padding: 0 30px;
            }
            @include large {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
                grid-gap: 18px;
                padding: 0;
            }
            @include xlarge {
                grid-gap: 24px;
            }
            .featured-item {
                background-color: $green;
                border-radius: 8px;
                box-shadow: 0 6px 10px 0 rgba(106, 106, 106, 0.39);
                margin-bottom: 14px;
                padding: 25px 30px 0;
                min-height: 265px;
                transition: box-shadow $trans-med ease, background-color $trans-med ease;
                @include medium {
                    margin-bottom: 18px;
                    padding: 30px 35px 0;
                    min-height: 300px;
                }
                @include xlarge {
                    padding: 48px 52px 0;
                    min-height: 350px;
                }
                &::before {
                    content:'';
                    background-image: url('../images/arrow-white.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    width: 30px;
                    height: 26px;
                    left: 25px;
                    bottom: 32px;
                    position: absolute;
                    @include medium {
                        width: 36px;
                        left: 35px;
                        height: 32px;
                        bottom: 30px;
                    }
                    @include xlarge {
                        left: 52px;
                        bottom: 48px;
                    }
                }
                &:last-of-type {
                    background-color: $blue;
                }
                .featured-category {
                    margin-bottom: 12px;
                    padding: 0 3px 5px;
                    display: table;
                    border-bottom: 1px solid rgba(255, 255, 255, .2);
                    min-width: 75%;
                    svg {
                        margin-right: 8px;
                        path {
                            fill: $white;
                        }
                    }
                    a {
                        color: $white;
                        font-style: italic;
                        font-size: .875rem;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    span {
                        color: $white;
                        display: inline-block;
                        margin: 0 6px;
                    }
                }
                .featured-link {
                    display: block;
                    padding-bottom: 74px;
                    @include medium {
                        padding-bottom: 76px;;
                    }
                    @include xlarge {
                        padding-bottom: 98px;
                    }
                    .featured-title {
                        color: $white;
                        font-size: 1.375rem;
                        margin-bottom: 20px;
                        @include medium {
                            font-size: 1.75rem;
                            margin-bottom: 24px;
                        }
                        @include xlarge {
                            font-size: 2rem;
                        }
                    }
                    .featured-date {
                        color: $white;
                        font-size: .875rem;
                        font-weight: $font-weight-bold;
                        margin-bottom: 8px;
                        @include xlarge {
                            font-size: 1.125rem;
                        }
                    }
                    .featured-desc {
                        color: $white;
                        p {
                            color: $white;
                            font-size: .875rem;
                            display: block;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            max-height: 3em;
                            line-height: 1.5;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            visibility: visible;
                            @include medium {
                                font-size: 1rem;
                                -webkit-line-clamp: 3;
                                max-height: 4.5em;
                                line-height: 1.5;
                            }
                            @include xlarge {
                                font-size: 1.125rem;
                            }
                        }
                    }
                }
                &.hover {
                    background-color: $green-dark;
                    box-shadow: 0 10px 20px 0 rgba(106, 106, 106, 0.55);
                    &:last-of-type {
                        background-color: $blue-dark;
                    }
                }
            }
        }
    }
    
    #filter-results {
        padding: 16px 12px 35px;
        transition: padding $trans-med ease;
        @include medium {
            padding: 0 40px 35px 375px;
            margin-top: 25px;
        }
        @include xlarge {
            padding: 12px 60px 35px 410px;
        }
        &.loading {
            opacity: .4;
        }
        .result-container {
            max-width: 1300px;
            margin: 0 auto;
            .result-item {
                margin-bottom: 18px;
                border-bottom: 1px solid $blue;
                padding: 0 30px 20px;
                float: left;
                @include xlarge {
                    margin-bottom: 40px;
                    padding: 0 70px 48px;
                    float: none;
                }
                .result-category {
                    font-size: .875rem;
                    font-style: italic;
                    color: $blue;
                    svg {
                        top: 2px;
                        margin-right: 6px;
                        path {
                            fill: $blue;
                        }
                    }
                    a {
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                    span {
                        font-style: normal;
                        margin: 0 12px;
                        @include xlarge {
                            margin: 0 16px;
                        }
                    }
                }
                .result-title {
                    margin: 20px 0 12px;
                    font-size: 1.25rem;
                    color: $blue;
                    max-width: 170px;
                    @include medium {
                        margin: 16px 0 12px;
                    }
                    @include xlarge {
                        font-size: 1.875rem;
                        margin: 14px 0 16px;
                        max-width: 1000px;
                    }
                }
                .result-date {
                    margin-right: 8px;
                }
                .result-author {
                    padding-left: 14px;
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 3px;
                        width: 1px;
                        height: 15px;
                        background-color: #226893;
                    }
                }
                .result-date, .result-author {
                    font-size: .875rem;
                    font-weight: $font-weight-semi;
                    @include xlarge {
                        margin-bottom: 24px;
                    }
                }
                .result-date-author-container {
                    margin-bottom: 12px;
                    @include xlarge {
                        margin-bottom: 24px;
                    }
                }
                .result-desc {
                    margin-bottom: 12px;
                    @include medium {
                        margin-bottom: 16px;
                    }
                    @include xlarge {
                        margin-bottom: 12px;
                    }
                    p {
                        font-size: .875rem;
                        display: block;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        max-height: 2.6em;
                        line-height: 1.3;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        visibility: visible;
                        @include medium {
                            font-size: 1rem;
                        }
                        @include xlarge {
                            font-size: 1.125rem;
                            max-width: 1000px;
                        }
                    }
                }
                .result-more {
                    font-weight: $font-weight-bold;
                    color: $blue;
                    font-size: 1rem;
                    background-image: url('../images/arrow-blue.svg');
                    background-position: right 6px;
                    background-repeat: no-repeat;
                    background-size: 16px;
                    padding-right: 24px;
                    @include medium {
                        font-size: 1.125rem;
                        background-size: 18px;
                        background-position: right 8px;
                        padding-right: 26px;
                    }
                    &:hover, &:focus {
                        border-bottom: 1px solid $blue;
                    }
                }
                &.highlighted {
                    display: table;
                    border-spacing: 25px 0;
                    padding: 0 30px 20px 5px;
                    width: 100%;
                    @include xlarge {
                        margin-bottom: 40px;
                        padding: 0 70px 48px 45px;
                    }
                    &::before {
                        content: '';
                        display: table-cell;
                        width: 5px;
                        height: 100%;
                        background-color: $orange;
                        border-top-left-radius: 2px;
                    }
                }
            }
        }
        .pagination {
            display: flex;
            justify-content: center;
            padding: 0;
            gap: 0;
            .pagination-button {
                font-size: 1.125rem;
                border-radius: 4px;
                border: 1px solid $black;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                top: 4px;
                width: 85px;
                right: -5px;
                transition: border-color $trans-med ease;
                span {
                    margin-left: 8px;
                    transition: color $trans-med ease;
                }
                svg {
                    top: 2px;
                    path {
                        transition: fill $trans-med ease;
                    }
                }
                &:hover {
                    border-color: $blue;
                    span {
                        color: $blue;
                    }
                    svg {
                        path {
                            fill: $blue;
                        }
                    }
                }
            }
            select {
                background-color: $white;
                z-index: 1;
                border-radius: 4px;
                border: 1px solid $black;
                background-position: center 90%;
                padding: 19px 24px;
                text-align: center;
                -moz-appearance: none; 
                -webkit-appearance: none; 
                appearance: none;
                background-image: url('../images/caret-dark.svg');
                background-position: center 90%;
                background-repeat: no-repeat;
                background-size: 12px;
                font-size: 1.25rem;
                box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
                + .pagination-button {
                    right: auto;
                    left: -5px;
                    top: 4px;
                    span {
                        margin-left: 0;
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    &.hidden {
        .filter-sidebar {
            left: -100%;
            height: 65px;
            @include medium {
                left: -340px;
                height: 0;
                margin-bottom: 100px;
            }
            @include xlarge {
                left: -370px;
                margin-bottom: 0;
            }
            .filter-toggle {
                width: 132px;
                right: -132px;
                border-radius: 0 8px 8px 0;
                text-align: center;
                background-color: $tan-light;
                box-shadow: 0 6px 20px rgba(106, 106, 106, .35);
                span {
                    display: inline-block;
                }
                svg {
                    transform: rotate(0deg);
                }
            }
            .filter-container {
                box-shadow: none;
            }
        }
        .featured-filter-section {
            padding: 35px 12px 12px;
            @include medium {
                padding: 100px 40px 12px;
            }
            @include xlarge {
                padding: 0 175px 12px 175px;
            }
            .featured-filter-container {
                @include medium {
                    display: grid;
                    grid-template-columns: repeat(2, minmax(0, 1fr));
                    grid-gap: 18px;
                    padding: 0;
                }
            }
        }
        #filter-results {
            padding: 16px 12px 35px;
            @include medium {
                padding: 12px 40px 35px;
            }
            @include xlarge {
                padding: 12px 175px 35px 175px;
            }
        }
    }
}

.hero-callout {
    + {
        .filter-wrapper {
            margin-top: -32px;
            @include medium {
                margin-top: -60px;
            }
            @include xlarge {
                margin-top: -75px;
            }
        }
    }
}