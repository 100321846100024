.video-callout {
    padding: 0 20px;
    margin: 40px auto;
    @include medium {
        padding: 0 40px;
    }
    @include xlarge {
        padding: 0 60px;
        max-width: 1640px;
        margin: 60px auto;
    }
    &__bar {
        position: absolute;
        height: 100%;
        width: 12px;
        top: 0;
        left: 0;
        @include medium {
            width: 14px;
        }
        @include xlarge {
            width: 16px;
        }
    }
    &__info {
        padding-left: 24px;
        margin-bottom: 32px;
        @include medium {
            padding-left: 32px;
            margin-bottom: 40px;
            max-width: 80%;
        }
        @include xlarge {
            max-width: 950px;
            width: 70%;
            padding-left: 48px;
            margin-bottom: 48px;
        }
    }
    &__heading {
    }
    &__subheading {
    }
    &__desc {
        margin-top: 16px;
        a {
            text-decoration: underline;
            color: $blue;
        }
    }
    &__video {
        border-radius: 12px;
        box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
        overflow: hidden;
        display: inline-block;
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9;
        max-width: 850px;
        @include xlarge {
            max-width: 1000px;
        }
        iframe {
            border-radius: 12px;
            width: 100%;
            height: 100%;
            border: 0;
            @include xlarge {
                max-width: 1000px;
            }
        }
        .play-video {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 60px;
            z-index: 2;
            transform: translate(-50%, -50%);
            @include xlarge {
                width: 80px;
            }
        }
        &.active {
            .video-callout__overlay, .play-video {
                display: none;
            }
        }
    }
    &__overlay {
        position: absolute;
        background-color: rgba(224, 217, 201,0.825);
        height: 100%;
        width: 100%;
        inset: 0;
        z-index: 1;
    }
}

.wp-block-column {
    .video-callout {
        padding: 0;
        .video-callout__video {
            width: 100%;
            height: auto;
            aspect-ratio: 16 / 9;
            iframe {
                height: 100%;
                width: 100%;
            }
        }
    } 
}