.social-share-container {
    padding: 20px 12px;
    @include medium {
        padding: 20px 40px;
    }
    @include xlarge {
        padding: 20px 60px 32px;
        max-width: 1640px;
        margin: 0 auto;
    }
    .social-share-title {
        display: inline-block;
        vertical-align: top;
        font-size: 1.25rem;
        margin-right: 12px;
        color: $blue;
        top: 2px;
    }
    .social-share-link {
        width: 36px;
        height: 36px;
        margin-right: 10px;
        display: inline-block;
        vertical-align: top;
        border-radius: 50%;
        background-color: $blue;
        transition: background-color $trans-med ease, box-shadow $trans-med ease;
        svg {
            width: 15px;
            height: 15px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            path {
                fill: $white;
            }
        }
        &:hover, &:focus {
            background-color: $blue-dark;
            box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
        }
    }
}

.post-content {
    .social-share-container {
        padding: 20px 0 0;
        @include medium {
            padding: 20px 0 0;
        }
        @include xlarge {
            padding: 20px 0 0;
            margin: 0 auto;
        }
        .social-share-title {
            margin-bottom: 0;
        }
    }
}