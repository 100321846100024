.split {
    margin-bottom: 32px;
    overflow: hidden;
    &.background-color--0061AA {
        background-color: #0061AA;
        .wysiwyg, .paragraph__title, .paragraph__text, .paragraph__link {
            color: $white;
        }
    }
    &.background-color--F5F6F7 {
        background-color: #F5F6F7;
    }
    &.full-width {
        padding: 32px 0;
        @include medium {
            padding: 40px 0;
        }
        @include xlarge {
            padding: 60px 0;
        }
        .split__inner-container {
            width: calc(100% - 40px);
            margin: 0 auto;
            @include medium {
                width: calc(100% - 80px);
            }
            @include xlarge {
                width: calc(100% - 120px);
                max-width: 1600px;
            }
        }
    }
    &__image-container {
        height: 100%;
        position: absolute;
        inset: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 110%;
            min-width: 110%;
        }
    }
    &__inner-container {
        @include medium {
            display: flex;
            flex-wrap: wrap;
            gap: 32px;
        }
        > div {
            @include medium {
                margin: 0;
            }
        }
        .image {
            @include medium {
                height: auto;
                max-height: none;
            }
        }
        .paragraph {
            &__text {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &.split-size--66 {
        .split__inner-container {
            > div  {
                &:nth-child(odd) {
                    @include medium {
                        width: calc(66.66% - 16px);
                        min-width: calc(66.66% - 16px);
                        margin: 0;
                    }
                }
                &:nth-child(even) {
                    @include medium {
                        width: calc(33.33% - 16px);
                        min-width: calc(33.33% - 16px);
                        margin: 0;
                    }
                }
            }
        }
    }
    &.split-size--33 {
        .split__inner-container {
            > div {
                &:nth-child(odd) {
                    @include medium {
                        width: calc(33.33% - 16px);
                        min-width: calc(33.33% - 16px);
                        margin: 0;
                    }
                }
                &:nth-child(even) {
                    @include medium {
                        width: calc(66.66% - 16px);
                        min-width: calc(66.66% - 16px);
                        margin: 0;
                    }
                }
            }
        }
    }
    &.split-size--50 {
        .split__inner-container {
            > div {
                &:nth-child(odd) {
                    @include medium {
                        width: calc(50% - 16px);
                        min-width: calc(50% - 16px);
                        margin: 0;
                    }
                }
                &:nth-child(even) {
                    @include medium {
                        width: calc(50% - 16px);
                        min-width: calc(50% - 16px);
                        margin: 0;
                    }
                }
            }
        }
    }
    &.split-size--25 {
        .split__inner-container {
            > div {
                &:nth-child(odd) {
                    @include medium {
                        width: calc(25% - 16px);
                        min-width: calc(25% - 16px);
                        margin: 0;
                    }
                }
                &:nth-child(even) {
                    @include medium {
                        width: calc(75% - 16px);
                        min-width: calc(75% - 16px);
                        margin: 0;
                    }
                }
            }
        }
    }
    &.split-size--75 {
        .split__inner-container {
            > div {
                &:nth-child(odd) {
                    @include medium {
                        width: calc(75% - 16px);
                        min-width: calc(75% - 16px);
                        margin: 0;
                    }
                }
                &:nth-child(even) {
                    @include medium {
                        width: calc(25% - 16px);
                        min-width: calc(25% - 16px);
                        margin: 0;
                    }
                }
            }
        }
    }
    &.vertical-align--middle {
        @include medium {
            align-items: center;
        }
    }
    &.vertical-align--bottom {
        @include medium {
            align-items: flex-end;
        }
    }
}