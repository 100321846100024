.site-main {
    .wp-block-column, .post-content {
        > .ngp-form {
            max-width: none;
            @include large {
                max-width: 1568px;
                padding: 0;
            }
            div.content.thankYou {
                width: 100%;
                text-align: left;
                .contributions {
                    width: 90%;
                    margin: 0 auto;
                    p {
                        font-family: lato,sans-serif;
                        color: $black;
                        font-size: 1.125rem;
                        line-height: 1.6;
                        a {
                            font-family: inherit;
                            color: $black;
                            &:hover, &:focus {
                                font-family: inherit;
                                color: $tan-dark;
                            }
                        }
                    }
                }
            }
            section.at-inner {
                background-color: transparent;
                @include medium {
                    padding: 0;
                }
                header.at-title {
                    color: $black;
                    font-family: lato,sans-serif;
                }
                header.at-markup h2 {
                    color: $black;
                    font-family: minerva-modern,sans-serif;
                    line-height: 1.3;
                }
                header.at-markup.HeaderHtml {
                    padding: 0;
                    div {
                        color: $black;
                        font-family: lato,sans-serif;
                    }
                }
                form {
                   fieldset.at-fieldset {
                        padding: .625rem 0;
                        legend.at-legend {
                            color: $black;
                            font-family: lato,sans-serif;
                            font-size: 1.75rem;
                            margin-bottom: 20px;
                            font-weight: $font-weight-norm;
                            margin-left: 0;
                        }
                        div.at-fields {
                            background-color: transparent;
                            label.at-check {
                                input[type=checkbox]+span:before {
                                    top: 3px;
                                }
                                input[type=checkbox]+span:after {
                                    top: 6px;
                                }
                                input[type=checkbox]:checked+span:before {
                                    background-color: $orange;
                                }
                                input[type=checkbox]:hover+span:before {
                                    border-color: $black;
                                    box-shadow: 0 0 4px 0 $orange;
                                }
                                input[type=checkbox]:focus+span:before {
                                    box-shadow: 0 0 4px 0 $orange;
                                }
                                span.at-checkbox-title, span.at-cover-costs-info {
                                    color: $black;
                                    font-family: lato,sans-serif;
                                    font-size: 1rem;
                                }
                                span.at-cover-costs-info {
                                    strong {
                                        font-family: Arial,Helvetica,sans-serif;
                                    }
                                }
                            }
                            div.at-row {
                                .radios {
                                    > label, .radio-description {
                                        color: $black;
                                        font-family: lato,sans-serif;
                                        font-size: 1.125rem;
                                    }
                                }
                                .at-radio {
                                    .at-radios {
                                        label.label-amount {
                                            font-family: lato,sans-serif;
                                            margin-bottom: 30px;
                                            > a {
                                                height: 52px;
                                                box-shadow: 0px 1px 10px rgba(0,0,0,.2);
                                            }
                                            input[type=radio]+a {
                                                background-color: rgba(93, 163, 125, .6);
                                            }
                                            input[type=radio]:checked+a {
                                                background-color: rgb(66, 92, 78);
                                            }
                                            input[type=radio]:hover+a {
                                                background-color: rgba(96, 119, 106, .8);
                                            }
                                        }
                                        label.label-amount.label-otheramount {
                                            margin-top: 6px;
                                            span.label-otheramount-prefix {
                                                padding-top: 9px;
                                            }
                                            input.edit-otheramount {
                                                border-color: #eae4d7 !important;
                                                border-width: 2px;
                                                padding: 8px 27px 10px;
                                                font-size: 1.375rem;
                                                color: #484848;
                                                font-family: lato,sans-serif;
                                                border-radius: 8px;
                                                height: 52px;
                                                &:focus, &:active {
                                                    border-color: $orange !important;
                                                }
                                            }
                                        }
                                    }
                                }
                                label.at-text, label.at-select {
                                    color: $black;
                                    font-family: lato,sans-serif;
                                    font-size: 1.125rem;
                                    margin-left: 0;
                                    input[type="text"], input[type="tel"], select, input[type="email"], .vgs-input-container iframe {
                                        border-color: #eae4d7 !important;
                                        border-width: 2px;
                                        padding: 8px 20px;
                                        font-size: 1.25rem;
                                        color: #484848;
                                        font-family: lato,sans-serif;
                                        border-radius: 8px;
                                        height: 52px;
                                        margin-top: 2px;
                                        margin-bottom: 24px;
                                        &:focus, &:active {
                                            border-color: $orange !important;
                                        }
                                    }
                                    .vgs-input-container.isFocused {
                                        iframe {
                                            border-color: $orange !important;
                                        }
                                    }
                                }
                                .updateMyProfileSection {
                                    span.text {
                                        color: $black;
                                        font-family: lato,sans-serif;
                                        font-size: 1.125rem;
                                        b, i, strong {
                                            font-family: inherit;
                                            color: $black;
                                        }
                                    }
                                    input[type=checkbox]:checked+span:before {
                                        background-color: $orange;
                                    }
                                    input[type=checkbox]:hover+span:before {
                                        border-color: $black;
                                        box-shadow: 0 0 4px 0 $orange;
                                    }
                                    input[type=checkbox]:focus+span:before {
                                        box-shadow: 0 0 4px 0 $orange;
                                    }
                                }
                            }
                        }
                    } 
                    .at-form-submit {
                        margin-bottom: 24px;
                        padding: 0.625rem 0 0;
                    }
                    .at-form-submit .at-submit.btn-at.btn-at-primary {
                        display: inline-block;
                        width: auto;
                        float: none;
                        font-size: 1.1875rem;
                        text-decoration: none;
                        font-weight: 700;
                        text-transform: uppercase;
                        background-color: $orange;
                        color: $white;
                        border: 0;
                        box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
                        padding: 16px 60px 16px 30px;
                        border-radius: 10px;
                        letter-spacing: 1px;
                        line-height: 1;
                        background-image: url('../images/arrow.svg');
                        background-repeat: no-repeat;
                        background-position: calc(100% - 30px) 49%;
                        text-shadow: none;
                        font-family: lato,sans-serif;
                        transition: background-color $trans-med ease;
                        margin: .625rem 0 0;
                        &:hover {
                            background-color: $red;
                        }
                    }
                }
                .at-markup.FooterHtml {
                    background-color: transparent;
                    p {
                        font-family: lato,sans-serif;
                        a {
                            font-family: inherit;
                            color: $black;
                            &:hover, &:focus {
                                font-family: inherit;
                                color: $orange-dark;
                            }
                        }
                    }
                }
            }
        }
    }
}


//VFB PRO

.vfbp-form {
    width: 900px;
    max-width: 92%;
    margin: 40px auto;
    background-color: $tan-light;
    box-shadow: 0 12px 30px 0 rgba(106, 106, 106, 0.2);
    border-radius: 8px;
    padding: 32px 20px;
    @include medium {
        margin: 60px auto;
        padding: 32px;
    }
    @include xlarge {
        padding: 48px;
    }
    h2 {
        color: $orange;
        margin-bottom: 16px;
    }
    .vfb-clearfix {
        display: none;
    }
    .vfb-col-12 {
        float: none !important;
    }
    .vfb-help-block {
        margin-bottom: 32px;
        display: block;
    }
    .vfb-form-group, .vfb-fieldType-radio {
        max-width: 450px;
        margin: 0 auto 24px;
        @include medium {
            max-width: 600px;
        }
        @include xlarge {
            max-width: 650px;
        }
		.vfb-radio {
			margin-bottom: 4px;
		}
    }
    .vfb-control-label {
        display: block;
        margin-bottom: 8px;
		line-height: 1.33;
        @include medium {
            font-size: 1.125rem;
        }
    }
    .vfb-form-control {
        width: 100%;
        border-color: #eae4d7;
        border-width: 2px;
        box-shadow: none;
        padding: 10px 24px;
        font-size: 1.25rem;
        border-radius: 8px;
        border-style: solid;
    }
    .vfb-fieldType-submit {
        text-align: center;
    }
}
