.hero-callout {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    margin-bottom: 32px;
    @include medium {
        margin-bottom: 60px;
    }
    @include xlarge {
        margin-bottom: 75px;
    }
    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: .9;
        &.yellow {
            background-image: linear-gradient(to right, #c4842e 70%, #e3c177);
        }
        &.red {
            background-image: linear-gradient(to right, #623735 70%, #BE8F8D);
        }
        &.green {
            background-image: linear-gradient(to right, #4B6557 70%, #869f91);
        }
        &.blue {
            background-image: linear-gradient(to right, #083E5F 70%, #4798C9);
        }
    }
    .hero-container {
        max-width: 1640px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: baseline;
        padding: 32px 20px;
        min-height: 132px;
        @include medium {
            text-align: left;
            padding: 45px 40px;
            min-height: 142px;
        }
        @include xlarge {
            padding: 60px;
            min-height: 195px;
        }
    }
    h1, .content, .button {
        color: $white;
        z-index: 2;
    }
    h1, .content {
        text-align: left;
        max-width: 500px;
        @include medium {
            max-width: 700px;
        }
        @include xlarge {
            max-width: 1000px;
        }
    }
    h1 {
        margin-bottom: 16px;
    }
    .content {
        h2, h3, h4, h5, h6, p {
            color: $white;
        }
        p {
            font-size: 1.125rem;
            @include medium {
                font-size: 1.25rem;
            }
            @include xlarge {
                font-size: 1.75rem;
            }
        }
    }
    .button {
        margin-top: 24px;
    }
    &.full-size {
        min-height: 320px;
        @include medium {
            min-height: 350px;
            padding: 52px 40px;
        }
        @include xlarge {
            min-height: 610px;
            padding: 80px 60px;
        }
        .overlay {
            opacity: .75;
            &.yellow {
                background-image: linear-gradient(to right, #8f5e1d 70%, #bfae8b);
            }
            &.red {
                background-image: linear-gradient(to right, #6c3b39 75%, #eae4d7);
            }
            &.green {
                background-image: linear-gradient(to right, #274a38 66%, #5c7366);
            }
            &.blue {
                background-image: linear-gradient(to right, #114e73 66%, #7aa8c4);
            }
        }
    }
}