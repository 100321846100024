.events {
    padding: 40px 0 20px;
    @include medium {
        padding-top: 50px;
    }
    @include xlarge {
        padding-top: 60px;
    }
    &__heading {
        text-align: center;
    }
    &__outer-container {
        overflow: scroll;
        white-space: nowrap;
        max-width: 1540px;
        margin: 0 auto;
        padding: 0 30px;
        text-align: center
    }
    &__container {
        display: table;
        padding: 30px 10px;
        border-spacing: 20px;
        margin: 0 auto;
        min-height: 330px;
        @include medium {
            min-height: 360px;
        }
    }
    &__card {
        display: table-cell;
        border-radius: 8px;
        white-space: normal;
        vertical-align: top;
        min-width: 210px;
        max-width: 210px;
        min-height: 230px;
        padding: 20px 28px 24px;
        box-shadow: 0 6px 10px 0 rgba(106, 106, 106, 0.39);
        @include medium {
            min-width: 250px;
            max-width: 250px;
            min-height: 280px;
        }
    }
    &__date-info {
        text-align: center;
    }
    &__month {
        font-size: 1rem;
        font-weight: $font-weight-semi;
        display: block;
        color: #19636e;
    }
    &__day {
        font-size: 3.4375rem;
        font-weight: $font-weight-bold;
        display: block;
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 12px;
        color: #19636e;
    }
    &__time {
        margin-bottom: 4px;
        font-size: .875rem;
        display: block;
        text-align: left;
    }
    &__title {
        font-size: 1rem;
        text-align: left;
    }
}