.carousel {
    overflow: hidden;
    &.full-width {
        padding: 32px 0;
        @include medium {
            padding: 40px 0;
        }
        @include xlarge {
            padding: 60px 0;
        }
    }
    &.page-width {
        width: calc(100% - 40px);
        margin: 0 auto;
        max-width: 1600px;
        @include medium {
            width: calc(100% - 80px);
        }
        @include xlarge {
            width: calc(100% - 120px);
        }
    }
    &.background-color--0061AA {
        background-color: #0061AA;
    }
    &.background-color--F5F6F7 {
        background-color: #F5F6F7;
    }
    &__container {
        margin: 0 auto;
        overflow: hidden;
        width: 83vw;
        max-width: 83vw;
        @include xlarge {
            width: 1050px;
            max-width: 1050px;
        }
    }
    &__image-container {
        height: 100%;
        position: absolute;
        inset: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 110%;
            min-width: 110%;
        }
    }
    &__inner-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
        @include large {
            gap: 44px;
        }
        @include xlarge {
            gap: 54px;
        }
        @include xxlarge {
            gap: 74px;
        }
        .image, .card, > div, > h1, > h2, > h3, > h4, > h5, > a {
            width: 83vw;
            min-width: 83vw;
            max-width: 83vw;
            display: block;
            height: 45vw;
            max-height: 600px;
            background-color: transparent;
            margin: 0;
            @include xlarge {
                width: 1050px;
                max-width: 1050px;
                min-width: 1050px;
            }
        }
        .card {
            height: auto;
            max-height: none;
            align-self: stretch;
        }
        .paragraph {
            height: auto;
            max-height: none;
        }
    }
    &__controls {
        display: flex;
        justify-content: center;
        padding: 32px 24px 24px;
        align-items: center;
        margin-bottom: 24px;
        @include xlarge {
            margin-bottom: 40px;
        }
    }
    &__counter {
        top: 1px;
        @include xlarge {
            top: 3px;
        }
        @include xxlarge {
            top: 5px;
        }
        span {
            color: $primary-color;
            font-size: 1.375rem;
            font-family: bicyclette, sans-serif;
            text-align: center;
            width: 20px;
            display: inline-block;
            @include xlarge {
                font-size: 2.625rem;
            }
            @include xxlarge {
                font-size: 3.0625rem;
                width: 25px;
            }
        }
        .counter__current {
            text-align: right;
            width: 36px;
            @include xlarge {
                width: 72px;
            }
            @include xxlarge {
                width: 90px;
            }
        }
        .counter__max {
            text-align: left;
            width: 36px;
            @include xlarge {
                width: 72px;
            }
            @include xxlarge {
                width: 90px;
            }
        }
    }
    &__prev-btn, &__next-btn {
        width: 45px;
        height: 45px;
        border: 0;
        background-color: transparent;
        padding: 6px 8px 10px;
        cursor: pointer;
        @include xlarge {
            padding: 3px 5px 7px;
        }
        @include xxlarge {
            padding: 1px 3px 5px;
        }
        svg {
            path {
                fill: $primary-color;
                transition: fill $trans-med ease;
            }
        }
        &:hover {
            svg {
                path {
                    fill: $primary-color-dark;
                }
            }
        }
    }
    &__prev-btn {
        svg {
            transform: rotate(180deg);
        }
    }
}

.row {
    .carousel {
        &.page-width, &.full-width {
            width: 100%;
        }
        &__container {
            @include medium {
                width: 48vw;
                max-width: 48vw;
            }
            @include xxlarge {
                width: 790px;
                max-width: 790px;
            }
        }
        &__inner-container {
            .image, .card, > div, > h1, > h2, > h3, > h4, > h5, > a {
                @include medium {
                    width: 48vw;
                    max-width: 48vw;
                    min-width: 48vw;
                }
                @include xxlarge {
                    width: 790px;
                    max-width: 790px;
                    min-width: 790px;
                }
            }
            .link-box {
                @include medium {
                    width: calc(48vw - 24px);
                    min-width: calc(48vw - 24px);
                    max-width: calc(48vw - 24px);
                }
                @include xxlarge {
                    width: 766px;
                    max-width: 766px;
                    min-width: 766px;
                }
            }
        }
    }
}