.large-photo-callout {
    .large-photo-container {
        @include medium {
            display: table;
            width: 100%;
            table-layout: fixed;
            min-height: 530px;
        }
        @include xlarge {
            min-height: 650px;
        }
        @include xxlarge {
            min-height: 750px;
        }
        .image-full {
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            min-height: 375px;
            height: 67vw;
            @include medium {
                display: table-cell;
                width: 100%;
                min-height: auto;
                height: 100%;
            }
        }
        .text-section {
            padding: 26px 42px 32px;
            margin-bottom: 0;
            text-align: left;
            @include medium {
                display: table-cell;
                width: 100%;
                padding: 32px 40px 40px 56px;
                vertical-align: middle;
            }
            @include xlarge {
                padding: 60px;
            }
            @include xxlarge {
                padding: 60px 100px;
            }
            h2 {
                font-size: 1.75rem;
                margin-bottom: 32px;
                max-width: 750px;
                @include medium {
                    font-size: 2.25rem;
                }
                @include xlarge {
                    font-size: 3rem;
                    margin-bottom: 24px;
                }
            }
            h2:empty {
                display: none;
            }
            .content {
                max-width: 750px;
                margin-bottom: 32px;
                @include xlarge {
                    margin-bottom: 56px;
                }
                h2, h3, h4, h5, h6 {
                    margin-bottom: 16px;
                    @include xlarge {
                        margin-bottom: 24px;
                    }
                }
                a {
                    color: $blue;
                    text-decoration: underline;
                }
                p {
                    margin-bottom: 16px;
                    @include medium {
                        font-size: 1.125rem;
                    }
                    @include xlarge {
                        margin-bottom: 24px;
                    }
                }
                p:empty {
                    display: none;
                }
                ul, ol {
                    padding-left: 20px;
                    li {
                        margin-bottom: 8px;
                        line-height: 1.25;
                        @include medium {
                            font-size: 1.125rem;
                        }
                    }
                }
            }
            .button:empty {
                display: none;
            }
        }
    }
}

.large-quote-callout {
    background-color: $tan-light;
    .large-quote-container {
        max-width: 1720px;
        margin: 0 auto;
        @include xlarge {
            display: table;
            table-layout: fixed;
            width: 100%;
            min-height: 500px;
        }
        .text-section {
            padding: 26px 42px 32px;
            @include medium {
                padding: 52px 75px 32px;
            }
            @include xlarge {
                padding: 60px;
                display: table-cell;
                vertical-align: middle;
                width: 100%;
            }
            @include xxlarge {
                padding: 60px 100px;
            }
            h2 {
                font-size: 1.75rem;
                margin-bottom: 32px;
                @include medium {
                    font-size: 2.25rem;
                }
                @include xlarge {
                    font-size: 3rem;
                    margin-bottom: 24px;
                }
            }
            .content {
                margin-bottom: 32px;
                p {
                    line-height: 1.5;
                    @include xlarge {
                        font-size: 1.125rem;
                    }
                }
            }
            .button:empty {
                display: none;
            }
        }
        .quote-section {
            padding: 26px 42px 32px;
            @include medium {
                padding: 40px 75px 32px;
            }
            @include xlarge {
                display: table-cell;
                vertical-align: middle;
                width: 100%;
                padding: 60px;
            }
            @include xxlarge {
                padding: 60px 100px;
            }
            .quote {
                font-size: 1.75rem;
                margin-bottom: 20px;
                padding: 0;
                padding-top: 68px;
                @include medium {
                    font-size: 2.5rem;
                    padding-top: 92px;
                    padding-left: 60px;
                }
                &::before {
                    content: '';
                    background-image: url('../images/quote-orange.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 95px;
                    height: 58px;
                    opacity: .5;
                    @include medium {
                        content: '';
                        width: 131px;
                        height: 80px;
                        display: block;
                    }
                    @include xlarge {
                        display: block;
                    }
                }
            }
            .author {
                text-align: right;
                .name {
                    font-size: 1.75rem;
                    margin-bottom: 8px;
                    color: $orange;
                }
                .desc {
                    font-size: 1.1875rem;
                    font-weight: $font-weight-bold;
                    text-transform: uppercase;
                    color: $orange;
                }
            }
        }
    }
}

.hero-callout {
    + .large-photo-callout {
        margin-top: -32px;
        @include medium {
            margin-top: -60px;
        }
        @include xlarge {
            margin-top: -75px;
        }
    }
}

.featured-resources-callout {
    padding: 48px 12px 42px;
    background-color: $white;
    text-align: center;
    @include medium {
        padding: 60px 12px 50px;
    }
    @include xlarge {
        padding: 90px 12px 64px;
    }
    h2 {
        color: $black;
        max-width: 350px;
        margin: 0 auto 32px;
        text-align: left;
        @media (min-width: 825px) {
            max-width: 750px;
        }
        @media (min-width: 1600px) {
            max-width: 1500px;
        }
    }
    .featured-resources-container {
        margin: 0 auto;
        text-align: center;
        @include medium {
            max-width: 900px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        @media (min-width: 1600px) {
            max-width: 1560px;
            display: table;
            border-collapse: separate;
            border-spacing: 20px;
            min-height: 547px;
        }
        .featured-resource {
            text-align: left;
            display: block;
            width: 351px;
            min-height: 508px;
            max-width: 100%;
            border-radius: 20px;
            box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
            overflow: hidden;
            margin: 0 auto 16px;
            padding: 192px 0 0;
            background-color: $white;
            @include medium {
                padding: 200px 0 0;
                display: inline-block;
                min-height: 547px;
                margin: 0 10px 24px;
            }
            @include large {
                width: 370px;
                margin: 0 18px 36px;
            }
            @media (min-width: 1600px) {
                margin: 0;
                display: table-cell;
            }
            .image-full {
                display: block;
                height: 162px;
                width: 100%;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                position: absolute;
                top: 0;
                left: 0;
                @include medium {
                    height: 168px;
                }
                &:hover {
                    ~ .multi-col-button {
                        background-color: $orange-dark;
                    }
                }
            }
            .category-container {
                padding: 0 38px;
                @include medium {
                    padding: 0 32px;
                }
                @include large {
                    padding: 0 40px;
                }
            }
            .category {
                font-size: .875rem;
                border-radius: 6rem;
                border: 1px solid $tan;
                margin-bottom: 6px;
                display: inline-block;
                padding: 1px 15px;
                text-transform: uppercase;
                transition: background-color $trans-med ease;
                @include medium {
                    font-size: 1rem;
                }
                &:last-of-type {
                    margin-bottom: 15px;
                }
                &:hover, &:focus {
                    background-color: $tan;
                }
            }
            .featured-item-details {
                padding: 0 38px 70px;
                display: block;
                @include medium {
                    padding: 0 32px 80px;
                }
                @include large {
                    padding: 0 40px 80px;
                }
                &:hover {
                    + .multi-col-button {
                        background-color: $orange-dark;
                    }
                }
            }
            .title {
                font-size: 1.875rem;
                margin-bottom: 12px;
                @include medium {
                    font-size: 2rem;
                }
            }
            .author {
                margin-bottom: 8px;
            }
            .desc {
                display: block;
                display: -webkit-box;
                -webkit-line-clamp: 5;
                max-height: 7.5em;
                line-height: 1.5;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                visibility: visible;
            }
            .multi-col-button {
                background-image: url('../images/skinny-arrow-white.svg');
                background-position: center;
                background-repeat: no-repeat;
                background-size: 21px;
                background-color: $orange;
                position: absolute;
                right: 0;
                bottom: 0;
                width: 57px;
                height: 55px;
                border-top-left-radius: 20px;
                transition: background-color $trans-med ease;
                &:hover {
                    background-color: $orange-dark;
                }
            }

        }
    }
    .button {
        margin-top: 30px;
        @include medium {
            margin-top: 10px;
        }
        @media (min-width: 1600px) {
            margin-top: 40px;
        }
        &:hover, &:focus {
            background-color: $orange-dark;
        }
    }
    &[style="background-color: #C4842E"] {
        h2 {
            color: $white;
        }
        .featured-resources-container {
            .featured-resource {
                .image-full {
                    &:hover {
                        ~ .multi-col-button {
                            background-color: $orange-dark;
                        }
                    }
                }
                .featured-item-details {
                    &:hover {
                        + .multi-col-button {
                            background-color: $red-dark;
                        }
                    }
                }
                .multi-col-button {
                    background-color: $red;
                }
            }
            
        }
        .button {
            background-color: $red;
            &:hover {
                background-color: $red-dark;
            }
        }
    }
    &[style="background-color: #7E4A48"], &[style="background-color: #60776A"], &[style="background-color: #226893"] {
        background-color: $blue;
        h2 {
            color: $white;
        }
    }
    &.off-white {
        background-color: $tan-light;
    }
    &.gray-light {
        background-color: $tan;
    }
}