.row__inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    @include medium {
        flex-direction: row;
        align-items: unset;
        flex-wrap: wrap;
        justify-content: center;
    }
    .card, .paragraph, .background {
        margin: 0;
        width: 100%;
    }
    .card {
        .i72-image, .i72-svg {
            @include medium {
                height: 25vw;
                max-height: 325px;
            }
            @include large {
                height: 22vw;
            }
        }
    }
    > div, > a {
        width: 100%;
        @include medium {
            flex: 1;
            min-width: calc(50% - 24px);
        }
    }
    > .link-box {
        width: calc(100% - 24px);
        @include medium {
            min-width: calc(50% - 42px);
        }
    }
    &.row--scrollable {
        .row__outer-container {
            max-width: 100%;
            overflow-x: scroll;
            .row__inner-container {
                display: flex;
                flex-direction: row;
                .card {
                    max-width: 512px;
                    min-width: 95%;
                    margin-left: 10px;
                    @include medium {
                        min-width: 512px;
                        margin-left: 20px;
                    }
                    @include large {
                        margin-left: 32px;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}

.row {
    overflow: hidden;
    &__image-container {
        height: 100%;
        position: absolute;
        inset: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 100%;
            min-width: 100%;
        }
    }
    &.vertical-align--middle, &.vertical-align--bottom {
        .image {
            @include large {
                flex: 1;
                height: 275px;
                max-height: none;
            }
        }
    }
    &.vertical-align--middle {
        .row__inner-container {
            @include large {
                align-items: center;
            }
        }
    }
    &.vertical-align--bottom {
        .row__inner-container {
            @include large {
                align-items: flex-end;
            }
        }
    }
    &.row-break--2 {
        .row__inner-container {
            > div, > a {
                @include large {
                    min-width: calc(50% - 12px);
                }
            }
            > .link-box {
                @include large {
                    min-width: calc(50% - 40px);
                }
            }
        }
    }
    &.row-break--3 {
        .row__inner-container {
            > div, > a {
                @include large {
                    min-width: calc(33% - 24px);
                }
            }
            > .link-box {
                @include large {
                    min-width: calc(33% - 40px);
                }
            }
        }
    }
    &.row-break--4 {
        .row__inner-container {
            > div, > a {
                @include large {
                    min-width: calc(25% - 24px);
                }
            }
            > .link-box {
                @include large {
                    min-width: calc(25% - 42px);
                }
            }
        }
    }
    &.row-break--5 {
        .row__inner-container {
            > div, > a {
                @include large {
                    min-width: calc(20% - 24px);
                }
            }
            > .link-box {
                @include large {
                    min-width: calc(20% - 48px);
                }
            }
        }
    }
    &.row-break--6 {
        .row__inner-container {
            > div, > a {
                @include large {
                    min-width: calc(16.5% - 24px);
                }
            }
            > .link-box {
                @include large {
                    min-width: calc(16.5% - 50px);
                }
            }
        }
    }
    &.background-color--0061AA {
        background-color: #0061AA;
    }
    &.background-color--F5F6F7 {
        background-color: #F5F6F7;
    }
    &.full-width {
        padding: 32px 0;
        @include medium {
            padding: 40px 0;
        }
        @include xlarge {
            padding: 60px 0;
        }
        .row__inner-container {
            width: calc(100% - 40px);
            margin: 0 auto;
            max-width: 1600px;
            padding: 0;
            @include medium {
                width: calc(100% - 80px);
            }
            @include xlarge {
                width: calc(100% - 120px);
            }
        }
    }
    &.row--mobile-scrollable {
        .row__inner-container {
            overflow-x: scroll;
            flex-direction: row;
            padding: 0 0 30px;
            scroll-snap-type: x mandatory;
            @include medium {
                flex-wrap: wrap;
                overflow-x: hidden;
            }
            > div, > a {
                scroll-snap-align: start;
                width: 100%;
                min-width: 200px;
                @include medium {
                    width: auto;
                    max-width: none;
                    min-width: calc(50% - 24px);
                }
            }
            .link-icon {
                width: 100%;
                min-width: 200px;
                min-height: auto;
                height: auto;
                @include medium {
                    width: 40%;
                    max-width: 300px;
                }
                @include large {
                    width: 100%;
                    min-width: calc(20% - 45px);
                    max-width: calc(20% - 45px);
                }
                @include xlarge {
                    min-width: calc(20% - 65px);
                    max-width: calc(20% - 65px);
                }
                @include xxlarge {
                    min-width: calc(20% - 105px);
                    max-width: calc(20% - 105px);
                }
                .i72-svg {
                    height: 100px;
                    width: auto;
                    svg {
                        width: 100%;
                        height: 100%;
                        @include medium {
                            filter: grayscale(85%);
                            opacity: .7;
                            transition: filter $trans-med ease, opacity $trans-med ease;
                        }
                    }
                }
                &:hover {
                    .i72-svg {
                        svg {
                            filter: grayscale(0);
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .row__feed-toggle {
            display: none;
        }
        .card {  
            min-width: calc(100vw - 40px);
            max-width: calc(100vw - 40px);
            @include medium {
                min-width: calc(50% - 24px);
                max-width: none;
            }
        }
    }
}