.text-section {
    padding: 0 40px;
    text-align: center;
    max-width: 1640px;
    margin: 0 auto 60px;
    @include medium {
        text-align: left;
    }
    @include xlarge {
        padding: 0 60px;
        margin-bottom: 80px;
    }
    h2 {
        margin-bottom: 12px;
        text-align: left;
        @include xlarge {
            margin-bottom: 24px;
        }
    }
    .content {
        margin-bottom: 32px;
        text-align: left;
        @include medium {
            max-width: 700px;
        }
        @include xlarge {
            margin-bottom: 42px;
            max-width: 1000px;
        }
        p {
            @include xlarge {
                font-size: 1.125rem;
            }
        }
    }
}

.hero-callout {
    + .text-section {
        @include medium {
            margin-top: -30px;
        }
    }
}

.event-list {
    padding-bottom: 75px;
    overflow: hidden;
    margin-bottom: 38px;
    @include medium {
        padding-bottom: 100px;
        margin-bottom: 50px;
    }
    @include xlarge {
        padding: 0 50px 125px;
        margin-bottom: 32px;
    }
    .swipe-to-scroll {
        display: none;
        padding-right: 15px;
        &::before {
            content: none;
        }
        &.active {
            display: block;
        }
    }
    h2 {
        max-width: 1540px;
        padding: 0 40px;
        margin: 0 auto;
        @include xlarge {
            padding: 0 10px;
            margin-bottom: 16px;
        }
    }
    .events-background {
        background-color: $tan;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 175px;
        @include medium {
            top: 200px;
        }
        @include xlarge {
            top: 225px;
        }
    }
    .events-container {
        padding-bottom: 15px;
    }
    .events-inner-container {
        overflow-x: scroll;
        white-space: nowrap;
        max-width: 1540px;
        margin: 0 auto;
        padding: 0 30px;
        text-align: center;
        @include xlarge {
            padding: 0;
        }
    }
    .events {
        white-space: nowrap;
        display: table;
        padding: 40px 10px;
        border-spacing: 20px;
        margin: 0 auto;

        .events-list {
            display: inline-block;
        }

        .event {
            background-color: $white;
            display: table-cell;
            vertical-align: top;
            min-width: 288px;
            max-width: 288px;
            min-height: 335px;
            border-radius: 8px;
            box-shadow: 0 6px 10px 0 rgba(106, 106, 106, 0.39);
            padding: 20px 30px 48px;
            margin-right: 20px;
            transition: box-shadow $trans-med ease;
            .month {
                display: block;
                font-size: 1.5rem;
                color: $blue;
                font-family: minerva-modern, sans-serif;
                text-align: center;
            }
            .day {
                display: block;
                font-size: 5.625rem;
                line-height: 1;
                color: $blue;
                font-family: minerva-modern, sans-serif;
                margin-bottom: 10px;
                border-bottom: 1px solid rgba(15, 48, 81, 0.2);
                text-align: center;
            }
            h5 {
                font-size: 1.5rem;
                font-family: lato, sans-serif;
                margin-bottom: 4px;
                color: $blue;
                text-align: left;
                white-space: normal;
                line-height: 1.125;
                font-weight: $font-weight-bold;
            }
            .timing {
                margin-bottom: 8px;
                text-align: left;
                white-space: normal;
                line-height: 1.3;
                span {
                    font-size: 1rem;
                    color: $black;
                }
            }
            svg {
                width: 24px;
                height: auto;
                position: absolute;
                bottom: 20px;
                left: 30px;
                transition: left $trans-med ease;
            }
            &:hover {
                box-shadow: 0 6px 10px 0 rgba(106, 106, 106, 0.65);
                svg {
                    left: 50px;
                }
            }
        }
    }
    .load-more {
        top: 150px;
        display: inline-block;
        vertical-align: top;
        
        &.hidden {
            display: none;
        }
    }
}

.event-list {
    + .multi-col-callout {
        + .featured-resources-callout {
            background-color: $white;
            margin-top: -40px;
            @include medium {
                margin-top: -60px;
            }
            h2 {
                color: $black;
            }
        }
    }
}

.event-list {
    + .featured-resources-callout {
        background-color: $white;
        margin-top: -40px;
        @include medium {
            margin-top: -60px;
        }
        h2 {
            color: $black;
        }
    }

}