.lesson-container {
    padding: 0 20px;
    @include medium {
        padding: 0 40px;
    }
    @include xlarge {
        padding: 0 60px;
        max-width: 1640px;
        margin: 0 auto 80px;
        display: table;
        table-layout: fixed;
        width: 100%;
    }
    .lesson-desc, .lesson-details {
        @include xlarge {
            display: table-cell;
            width: 100%;
        }
        h2 {
            margin-bottom: 32px;
            @include medium {
                margin-bottom: 24px;
            }
        }
    }
    .lesson-desc {
        margin-bottom: 56px;
        @include xlarge {
            margin-bottom: 0;
            padding-right: 45px;
        }
        h2, h3, h4, h5, h6, p {
            margin-bottom: 16px;
        }
    }
    .lesson-details {
        margin-bottom: 36px;
        @include medium {
            margin-bottom: 70px;
        }
        @include xlarge {
            margin-bottom: 0;
            padding-left: 45px;
        }
        ul {
            list-style-position: inside;
            list-style-type: none;
            font-size: 1.125rem;
            text-indent: -26px;
            padding-left: 22px;
            li {
                margin-bottom: 32px;
                @include xlarge {
                    margin-bottom: 36px;
                }
                &::before {
                    content: '';
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    background-color: $blue;
                    display: inline-block;
                    margin-right: 12px;
                }
            }
        }
    }
}

.lesson-parts-container {
    padding: 0 24px;
    margin-bottom: 80px;
    @include medium {
        padding: 0 40px;
    }
    .lesson-part {
        margin-bottom: 24px;
        @include medium {
            margin-bottom: 20px;
        }
        @include xlarge {
            padding: 0 32px;
            max-width: 1520px;
            margin: 0 auto 20px;
        }
        .lesson-part-title {
            display: block;
            padding: 25px 0 45px 28px;
            border-radius: 5px;
            box-shadow: 0 3px 3px rgba(0, 0, 0, .15);
            transition: background-color $trans-med ease;
            @include medium {
                padding: 32px 28px 32px 40px;
            }
            @include xlarge {
                padding: 32px 32px 32px 50px;
            }
            h2 {
                font-family: lato, sans-serif;
                color: $blue;
                font-size: 1.875rem;
                font-weight: $font-weight-bold;
                display: inline-block;
                max-width: 65%;
                line-height: 1.125;
                transition: color $trans-med ease;
            }
            svg {
                width: 28px;
                height: 28px;
                position: absolute;
                top: 23px;
                right: 19px;
                transition: all $trans-fast ease;
                @include medium {
                    width: 32px;
                    height: 32px;
                    top: 50%;
                    right: 30px;
                    transform: translateY(-50%);
                }
                @include xlarge {
                    width: 36px;
                    height: 36px;
                }
                g {
                    path {
                        transition: stroke $trans-med ease;
                    }
                }
            }
            &::before, &::after {
                content: '';
                background-color: $blue;
                height: 27px;
                width: 4px;
                display: block;
                position: absolute;
                border-radius: 6px;
                right: 28px;
                top: 24px;
                transition: height $trans-med ease, top $trans-med ease, transform $trans-med ease;
                @include medium {
                    height: 32px;
                    top: 32px;
                    right: 36px;
                }
                @include xlarge {
                    right: 40px;
                }
            }
            &::after {
                transform: rotate(90deg);
            }
            &:hover {
                background-color: $blue;
                h2 {
                    color: $white;
                }
                &::before, &::after {
                    background-color: $white;
                }
            }
        }
        .lesson-dropdown {
            max-height: 0;
            visibility: hidden;
            overflow: hidden;
            padding: 0 32px;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            transition: max-height $trans-slow ease, padding $trans-slow ease, visibility $trans-slow ease, box-shadow $trans-slow ease;
            @include medium {
                padding: 0 60px;
                transition: max-height $trans-med ease, padding $trans-med ease, visibility $trans-med ease, box-shadow $trans-med ease;
            }
            @include xlarge {
                padding: 0 90px;
            }
            .lesson-part-desc {
                @include xlarge {
                    column-count: 2;
                    -webkit-perspective:1;
                    -webkit-column-count:2;
                    -moz-column-count:2;
                    column-gap: 100px;
                }
                p {
                    font-size: 1.125rem;
                }
                a {
                    color: $blue;
                    text-decoration: underline;
                }
                h2, h3, h4, h5, h6 {
                    margin-bottom: 16px;
                }
                ul > li, ol > li {
                    margin-left: 20px;
                    margin-bottom: 12px;
                    font-size: 1.125rem;
                }
                div.aligncenter, div.alignleft, div.alignright {
                    max-width: 100%;
                    img {
                        max-width: 100%;
                    }
                    .wp-caption-text {
                        font-size: 1rem;
                    }
                }
                div.aligncenter {
                    margin: 0 auto;
                    text-align: center;
                }
                div.alignright {
                    float: right;
                    margin: 16px 0 16px 16px;
                }
                div.alignleft {
                    float: left;
                    margin: 16px 16px 16px 0;
                }
            }
            .lesson-part-materials {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin-top: 16px;
                a {
                    display: flex;
                    margin-top: 24px;
                    margin-right: 24px;
                    width: 260px;
                    padding: 18px 22px 18px 26px;
                    background-color: #F4F7FA;
                    border-radius: 5px;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
                    align-items: center;
                    transition: background-color $trans-med ease;
                    @include medium {
                        margin-top: 12px;
                        margin-right: 12px;
                    }
                    @include xlarge {
                        margin-top: 32px;
                        margin-right: 32px;
                    }
                    svg {
                        margin-right: 15px;
                        min-width: 48px;
                        min-height: 64px;
                        max-width: 48px;
                        max-height: 64px;
                        path {
                            transition: fill $trans-med ease;
                        }
                    }
                    span {
                        font-size: 1.125rem;
                        font-weight: $font-weight-bold;
                        transition: color $trans-med ease;
                    }
                    &:hover {
                        background-color: $blue;
                        svg {
                            path {
                                fill: $white;
                            }
                        }
                        span {
                            color: $white;
                        }
                    }
                }
            }
        }
        &.active {
            .lesson-part-title {
                background-color: $blue;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                h2 {
                    color: $white;
                }
                &::before {
                    transform: rotate(45deg);
                    background-color: $white;
                }
                &::after {
                    transform: rotate(135deg);
                    background-color: $white;
                }
            }
            .lesson-dropdown {
                max-height: 5000px;
                visibility: visible;
                padding: 36px 32px;
                box-shadow: 0 6px 20px rgba(106, 106, 106, 0.39);
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
                transition: max-height $trans-med ease, padding $trans-med ease, visibility $trans-med ease, box-shadow $trans-med ease;
                @include medium {
                    padding: 52px 60px;
                }
                @include xlarge {
                    padding: 52px 90px;
                }
            }
        }
    }
}

.lesson-additionals {
    padding: 0 20px;
    @include medium {
        padding: 0 40px;
    }
    @include xlarge {
        padding: 0 60px;
        max-width: 1640px;
        margin: 0 auto 80px;
    }
    h2 {
        margin-bottom: 32px;
        @include medium {
            margin-bottom: 40px;
        }
    }
    .lesson-additionals-container {
        display: flex;
        flex-wrap: wrap;
        a {
            display: flex;
            margin-bottom: 24px;
            margin-right: 24px;
            width: 260px;
            padding: 18px 22px 18px 26px;
            background-color: #F4F7FA;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
            align-items: center;
            transition: background-color $trans-med ease;
            svg {
                margin-right: 15px;
                min-width: 48px;
                min-height: 64px;
                max-width: 48px;
                max-height: 64px;
                path {
                    transition: fill $trans-med ease;
                }
            }
            span {
                font-size: 1.125rem;
                font-weight: $font-weight-bold;
                transition: color $trans-med ease;
            }
            &:hover {
                background-color: $blue;
                svg {
                    path {
                        fill: $white;
                    }
                }
                span {
                    color: $white;
                }
            }
        }
    }
}