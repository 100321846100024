.carousel-callout {
    background-color: $green;
    padding: 45px 0 50px;
    text-align: center;
    margin-bottom: 32px;
    @include medium {
        margin-bottom: 60px;
    }
    @include xlarge {
        padding: 80px 0;
        margin-bottom: 100px;
    }
    h2 {
        color: $black;
        margin-bottom: 40px;
        padding: 0 40px;
        @include xlarge {
            margin-bottom: 65px;
        }
    }
    .slider {
        overflow: hidden;
        margin: 20px auto 0 auto;
        @include medium {
            padding: 0 120px;
        }
        @include xlarge {
            max-width: 1400px;
            padding: 0 150px;
        }
        .slides {
            margin: 0;
            padding: 0;
            list-style: none;
            white-space: nowrap;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;
            padding-bottom: 20px;
            margin-bottom: 12px;
            @include medium {
                white-space: unset;
                overflow-x: unset;
                margin-bottom: 0;
                padding-bottom: 0;
            }
            li {
                display: inline-block;
                vertical-align: top;
                scroll-snap-align: center;
                margin: 0;
                padding: 0;
                width: 100vw;
                height: 100%;
                text-align: center;
                &.active {
                    visibility: visible;
                }
                @include medium {
                    visibility: hidden;
                    text-align: left;
                    display: block;
                    float: left;
                }
                .prof-pic {
                    display: block;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 180px;
                    height: 180px;
                    margin: 0 auto 30px;
                    @include medium {
                        display: inline-block;
                        vertical-align: top;
                        height: 230px;
                        width: 230px;
                        margin: 0 36px 0 0;
                    }
                    @include xlarge {
                        height: 230px;
                        width: 230px;
                    }
                }
                .slide-content {
                    display: block;
                    width: 60%;
                    margin: 0 auto;
                    text-align: center;
                    color: $white;
                    @include medium {
                        text-align: left;
                        display: inline-block;
                        vertical-align: top;
                        width: calc(100% - 510px);
                    }
                    @include xlarge {
                        width: calc(100% - 550px);
                        max-width: 850px;
                    }
                    .name, .role, .carousel-bio, .bio-link {
                        color: $black;
                    }
                    .name {
                        text-align: center;
                        font-size: 1.625rem;
                        margin-bottom: 12px;
                        white-space: normal;
                        @include medium {
                            text-align: left;
                            font-size: 1.75rem;
                        }
                        @include xlarge {
                            font-size: 2rem;
                            margin-bottom: 8px;
                        }
                    }
                    .role {
                        font-size: 1rem;
                        font-weight: $font-weight-semi;
                        text-transform: uppercase;
                        margin-bottom: 16px;
                        white-space: normal;
                    }
                    .carousel-bio {
                        p {
                            font-size: 1rem;
                            color: $black;
                            margin-bottom: 24px;
                            text-align: left;
                            white-space: normal;
                            display: block;
                            display: -webkit-box;
                            -webkit-line-clamp: 6;
                            max-height: 7.8em;
                            line-height: 1.3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            visibility: visible;
                            @include medium {
                                max-height: 6.5em;
                                -webkit-line-clamp: 5;
                            }
                            @include xlarge {
                                margin-bottom: 20px;
                                font-size: 1.25rem;
                                max-height: 5.2em;
                                line-height: 1.3;
                                -webkit-line-clamp: 4;
                            }
                        }
                        a {
                            text-decoration: underline;
                        }
                    }
                    .bio-link {
                        font-size: 1.125rem;
                        font-weight: $font-weight-semi;
                        text-transform: uppercase;
                        background-position: 100% 5px;
                        background-image: url('../images/small-arrow-black.svg');
                        padding-right: 28px;
                        display: inline-block;
                        background-size: 18px;
                        padding-bottom: 2px;
                        background-repeat: no-repeat;
                        &:hover, &:focus {
                            border-bottom: 2px solid;
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
        .left-arrow, .right-arrow {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 20px;  
            z-index: 2;
            display: none;
            @include medium {
                left: 45px;
                display: block;
            }
            svg {
                height: 32px;
                @include medium {
                    height: auto;
                }
                path {
                    stroke: #000;
                    transition: stroke $trans-med ease;
                }
            }
            &:hover {
                svg {
                    path {
                        stroke: #fff;
                    }
                }
            }
        }
        .right-arrow {
            left: auto;
            right: 20px;
            @include medium {
                left: auto;
                right: 45px;
            }
        }
        .left-arrow {
            svg {
                transform: rotate(180deg);
            }
        }
    }
    .swipe-to-scroll {
        color: $black;
        &::after, &::before {
            background-image: url('../images/arrow-black.svg');
        }
        @include medium {
            display: none;
        }
    }
    &[style="background-color: #7E4A48"], &[style="background-color: #60776A"], &[style="background-color: #226893"],&[style="background-color: #C4842E"] {
        h2 {
            color: $white;
        }
        .slider {  
            .slides {
                li {
                    .slide-content {
                        .name, .role, .carousel-bio, .bio-link {
                            color: $white;
                        }
                        .carousel-bio {
                            p, a {
                                color: $white;
                            }
                        }
                        .bio-link {
                            background-image: url('../images/small-arrow.svg');
                        }
                    }
                }
            }
            .left-arrow, .right-arrow {
                svg {
                    path {
                        stroke: #fff;
                    }
                }
                &:hover {
                    svg {
                        path {
                            stroke: #000;
                        }
                    }
                }
            }
            .swipe-to-scroll {
                color: $white;
                &::after, &::before {
                    background-image: url('../images/arrow-white.svg');
                }
            }
        }
    }
}