.hero {
    min-height: 350px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;

    @include medium {
        min-height: 450px;
    }
    @include xlarge {
        min-height: 500px;
    }
    @include xxlarge {
        min-height: 588px;
    }
    .overlay {
        position: absolute;
        inset: 0;
        opacity: .65;
        z-index: 1;
        &.blue {
            background-image: linear-gradient(to right, #114e73 66%, #7aa8c4);
        }
    }
    &__inner-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: 20px;
        width: 100%;
        z-index: 2;
        @include medium {
            padding: 40px;
        }
        @include xlarge {
            padding: 60px;
            max-width: 1520px;
            margin: 0 auto;
        }
    }
    &__title {
        margin-bottom: 16px;
        max-width: 550px;
        color: $white;
        @include xlarge {
            max-width: 750px;
            margin-bottom: 24px;
        }
    }
    &__text {
        margin-bottom: 24px;
        max-width: 550px;
        line-height: 1.35;
        color: $white;
        @include xlarge {
            max-width: 750px;
            line-height: 1.5;
            margin-bottom: 32px;
        }
    }
    &__button {
        //
    }
}