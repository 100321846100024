.top-bar {
    background-color: rgba(17, 41, 58, 0.85);
    height: 50px;
    z-index: 1000;
    .top-bar-container {
        max-width: 1650px;
        padding: 0 12px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: flex-end;
        a {
            color: $white;
            font-size: 1rem;
            text-decoration: none;
            padding: 0 12px;
            display: flex;
            height: 100%;
            align-items: center;
            transition: all $trans-med ease;
            @include medium {
                padding: 0 18px;
                margin: 0 8px;
            }
            @include xlarge {
                padding: 0 24px;
                margin: 0 12px;
            }
            svg {
                margin-right: 8px;
                path {
                    transition: fill $trans-med ease;
                }
            }
            &:hover {
                background-color: rgba(155,174,181,0.5);
            }
            &.active {
                background-color: #9BAEBA;
                box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.1);
                height: 55px;
                position: absolute;
                bottom: 0;
                padding-top: 5px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                color: #11293a;
                svg {
                    path {
                        fill: #11293a;
                    }
                }
                &::after {
                    content: '';
                    background-image: url('../images/arrow-blue.svg');
                    background-position: center;
                    background-size: 13px;
                    background-repeat: no-repeat;
                    position: absolute;
                    top: 2px;
                    left: 50%;
                    width: 21px;
                    height: 21px;
                    transform: translateX(-50%) rotate(270deg);
                }
            }
        }
    }
}

.nav-container {
    position: relative;
    z-index: 1000;
    width: 100%;
    @include xlarge {
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.07);
    }
    .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(255, 255, 255, 0.9);
    }
    .nav-inner-container {
        max-width: 1850px;
        margin: 0 auto;
    }
    .mobile-container {
        height: 75px;
        @include medium {
            height: 85px;
        }
        .logo {
            display: inline-block;
            height: 100%;
            width: 200px;
            padding: 8px;
            z-index: 3;
            @include medium {
                width: 15%;
                padding: 12px;
            }
            svg {
                width: 100%;
                height: 100%;
            }
            .i72-image {
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                height: 100%;
            }
            .i72-svg {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 100%;
                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .menu-toggle {
            position: absolute;
            top: 0;
            right: 0;
            z-index: 2;
            background-color: transparent;
            transition: height $trans-med ease, box-shadow $trans-med ease, background-color $trans-med ease, padding $trans-med ease;
            padding: 25px 27px 23px;
            height: 75px;
            @include medium {
                padding: 27px 29px 23px;
                height: 85px;
            }
            @include xlarge {
                display: none;
            }   
            span {
                background-color: $primary-color-dark;
                width: 31px;
                height: 6px;
                margin-bottom: 5px;
                display: block;
                opacity: 1;
                transition: all $trans-med ease;
                &:nth-child(2) {
                    transition: opacity $trans-fast ease;
                }
            }
        }
    }
    .main-nav {
        background-color: $white;
        padding: 0 18px;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        width: 100%;
        transition: padding $trans-med ease, max-height $trans-med ease;
        @include medium {
            padding: 0 32px;
        }
        @include xlarge {
            padding: 0;
            width: auto;
            position: relative;
            max-height: none;
            overflow: visible;
            background-color: transparent;
            position: absolute;
            top: 0;
            right: 0;
            box-shadow: none;
        }
        #menu-main-nav {
            list-style: none;
            @include xlarge {
                display: table;
                table-layout: fixed;
                height: 85px;
                width: 100%;
                padding-right: 12px;
                width: 85%;
                margin-left: auto;
            }
            @include xxlarge {
                padding-right: 24px;
                border-bottom-right-radius: 2px;
                border-top-right-radius: 2px;
            }
            li {
                cursor: pointer;
                @include xlarge {
                    display: table-cell;
                    width: 100%;
                    height: 100%;
                    vertical-align: middle;
                    text-align: center;
                }
                a {
                    font-size: 1.375rem;
                    display: block;
                    font-weight: $font-weight-semi;
                    text-transform: uppercase;
                    line-height: 1.41;
                    color: $black;
                    text-decoration: none;
                    padding: 16px 32px 16px 0;
                    border-bottom: 1px solid rgba(72, 72, 72, .5);
                    transition: background-color $trans-med ease;
                    @include medium {
                        padding: 20px 48px 20px 0;
                        font-size: 1.75rem;
                    }
                    @include xlarge {
                        font-size: .875rem;
                        font-weight: $font-weight-semi;
                        border-bottom: none;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 12px 20px;
                    }
                    @media (min-width: 1600px) {
                        font-size: 1rem;
                        padding: 12px 24px;
                    }
                    @include xxlarge {
                        font-size: 1.125rem;
                        padding: 12px 20px;
                    }
                    &:hover {
                        @include xlarge {
                            background-color: rgba(169, 206, 222,0.5);
                        }
                    }
                }
                .sub-menu {
                    padding: 0 0 0 27px;
                    max-height: 0;
                    overflow: hidden;
                    visibility: hidden;
                    list-style: none;
                    transition: padding $trans-med ease, max-height $trans-med ease, visibility $trans-med ease;
                    @include medium {
                        padding: 0 0 0 76px;
                    }
                    @include xlarge {
                        position: absolute;
                        width: 398px;
                        padding: 0 32px;
                        left: 0;
                        background-color: rgba(255, 255, 255, .9);
                        top: 85px;
                    }
                    li {
                        @include xlarge {
                            display: block;
                        }
                        a {
                            border-bottom: none;
                            padding: 20px 0 0;
                            @include medium {
                                padding: 32px 0 0;
                            }
                            @include xlarge {
                                padding: 12px;
                                font-size: 1rem;
                                height: auto;
                                display: block;
                                text-align: left;
                                font-weight: $font-weight-semi;
                            }
                        }
                        &.current-menu-item {
                            a {
                                @include xlarge {
                                    box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
                                    background-color: $secondary-color-light;
                                    position: relative;
                                    width: 100%;
                                    top: auto;
                                    border-radius: 0;
                                }
                            }
                        }
                    }
                    &.active {
                        max-height: 1000px;
                        padding: 0 0 18px 27px;
                        visibility: visible;
                        @include medium {
                            padding: 0 0 40px 76px;
                        }
                        @include xlarge {
                            padding: 20px 32px;
                            box-shadow: 0 10px 30px 0 rgba(0, 0, 0, .1);
                        }
                    }
                }
                &.menu-item-has-children {
                    > a {
                        &::after {
                            content: '';
                            background-image: url('../images/caret.svg');
                            background-size: 100%;
                            background-position: center;
                            background-repeat: no-repeat;
                            width: 12px;
                            height: 12px;
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 6px;
                            opacity: .6;
                            transition: transform $trans-med ease;
                            @include medium {
                                width: 18px;
                                height: 18px;
                                right: 18px;
                                opacity: 1;
                            }
                            @include xlarge {
                                left: 50%;
                                transform: translateX(-50%);
                                right: auto;
                                top: auto;
                                bottom: 5px;
                                width: 10px;
                                height: 10px;
                                opacity: 0.75;
                            }
                        }
                        &.active {
                            &::after {
                                transform: translateY(-50%) rotate(180deg);
                                @include xlarge {
                                    transform: translateX(-50%) rotate(180deg);
                                }
                            }
                        }
                    }
                }
                &.current-menu-item, &.current-menu-parent {
                    > a {
                        @include xlarge {
                            box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
                            background-color: $secondary-color-light;
                            position: absolute;
                            width: 100%;
                            top: 0;
                            padding-bottom: 17px;
                        }
                    }
                }
                &:last-child {
                    .sub-menu {
                        @include xlarge {
                            right: -12px;
                            left: auto;
                        }
                        @include xxlarge {
                            right: -24px;
                        }
                    }
                }
                &:nth-last-child(2) {
                    .sub-menu {
                        @include xlarge {
                            left: -100px;
                        }
                    }
                }
            }
        }
    }
    &.active {
        .mobile-container {
            height: 75px;
            @include medium {
                height: 85px;
            }
            @include xlarge {
                height: 100px;
            }
            .menu-toggle {
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                height: 83px;
                background-color: $white;
                padding: 25px 23px 23px;
                @include medium {
                    padding: 33px 31px 23px;
                    height: 96px;
                }
                span {
                    transform: rotate(45deg);
                    margin-top: 14px;
                    height: 3px;
                    width: 28px;
                    background-color: $black;
                    @include medium {
                        background-color: $black;
                        width: 34px;
                    }
                    &:nth-child(2) {
                        opacity: 0;
                    }
                    &:last-child {
                        transform: rotate(135deg);
                        margin-top: -25px;
                    }
                }
            }
        }
        .main-nav {
            background-color: $white;
            padding: 6px 18px 50px;
            max-height: 5000px;
            @include medium {
                padding: 6px 32px 65px;
            }
            @include xlarge {
                padding: 0;
                background-color: transparent;
            }
        }
    }
}
