.row--mobile-carousel {
    overflow: hidden;
    .row__inner-container {
        flex-direction: row;
        > div, > a {
            width: calc(100vw - 40px);
            max-width: calc(100vw - 40px);
            min-width: calc(100vw - 40px);
            @include medium {
                width: auto;
                max-width: none;
                min-width: calc(50% - 24px);
            }
        }
    }
    .card {  
        min-width: calc(100vw - 40px);
        max-width: calc(100vw - 40px);
        @include medium {
            min-width: calc(50% - 24px);
            max-width: none;
        }
    }
    &__controls {
        display: flex;
        justify-content: center;
        padding: 32px 24px 24px;
        align-items: center;
        @include medium {
            display: none;
        }
    }
    &__counter {
        span {
            color: $primary-color;
            font-size: 1.375rem;
            text-align: center;
            width: 20px;
            @include xlarge {
                font-size: 2.625rem;
            }
            @include xxlarge {
                font-size: 3.0625rem;
                width: 25px;
            }
        }
        .counter__current {
            text-align: right;
            width: 36px;
            @include xlarge {
                width: 72px;
            }
            @include xxlarge {
                width: 90px;
            }
        }
        .counter__max {
            text-align: left;
            width: 36px;
            @include xlarge {
                width: 72px;
            }
            @include xxlarge {
                width: 90px;
            }
        }
    }
    &__prev-btn, &__next-btn {
        width: 45px;
        height: 45px;
        border: 0;
        background-color: transparent;
        padding: 6px 8px 10px;
        cursor: pointer;
        @include xlarge {
            padding: 3px 5px 7px;
        }
        @include xxlarge {
            padding: 1px 3px 5px;
        }
        svg {
            path {
                fill: $primary-color;
                transition: fill $trans-med ease;
            }
        }
        &:hover {
            svg {
                path {
                    fill: $primary-color-dark;
                }
            }
        }
    }
    &__prev-btn {
        svg {
            transform: rotate(180deg);
        }
    }
}