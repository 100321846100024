.search-results-container {
    background-color: $tan-light;
    text-align: left;
    max-height: 0;
    overflow: hidden;
    transition: max-height $trans-med ease, padding $trans-med ease;
    .close-search-results {
        display: table;
        margin: 12px auto;
        font-weight: $font-weight-bold;
        font-size: 1.125rem;
        letter-spacing: 2px;
        position: relative;
        background-color: transparent;
        color: #484848;
        width: auto;
        height: auto;
        &:hover {
            text-decoration: underline;
            background-color: transparent;
        }
    }
    .pagination-container {
        max-width: 1080px;
        margin: 0 auto 20px;
        display: flex;
        justify-content: space-between;
        a {
            &.next-results {
                padding: 8px 3px 2px;
                font-size: 1.125rem;
                color: #1B5D86;
                font-weight: $font-weight-bold;
                svg {
                    width: 12px;
                    margin-left: 6px;
                    transform: rotate(270deg);
                }
                &:hover {
                    border-bottom: 1px solid #1B5D86;
                    padding-bottom: 1px;
                    text-decoration: none;
                }
            }
            &.prev-results {
                padding: 8px 3px 2px;
                font-size: 1.125rem;
                color: #1B5D86;
                font-weight: $font-weight-bold;
                svg {
                    width: 12px;
                    margin-right: 6px;
                    transform: rotate(90deg);
                }
                &:hover {
                    border-bottom: 1px solid #1B5D86;
                    padding-bottom: 1px;
                    text-decoration: none;
                }
            }
        }
    }
    &.desktop {
        display: none;
        @include medium {
            display: block;
            padding: 0 40px;
        }
        @include xlarge {
            padding: 0 60px;
        }
        .close-search-results {
            margin: 0;
            float: right;
        }
        &.active {
            @include medium {
                padding: 40px 40px 100px;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
                z-index: 999;
                max-height: 5000px;
            }
            @include xlarge {
                padding: 60px 60px 125px;
            }
        }
    }
    &.mobile {
        padding: 0 16px;
        margin-left: -16px;
        margin-right: -16px;
        margin-bottom: 0;
        transition: padding $trans-med ease, margin-bottom $trans-med ease;
        @include medium {
            display: none;
        }
        &.active {
            max-height: 5000px;
            padding: 32px 16px;
            margin-bottom: 48px;
        }
    }
    .result-count {
        display: block;
        font-size: 1.125rem;
        max-width: 1080px;
        font-weight: $font-weight-bold;
        padding: 0 0 18px;
        border-bottom: 1px solid #d6d6d6;
        margin: 0 auto 32px;
    }
    .result {
        padding-bottom: 28px;
        border-bottom: 1px solid #d6d6d6;
        margin: 0 auto 24px;
        max-width: 1080px;
        h5 {
            font-size: 1.375rem;
            margin-bottom: 12px;
            font-weight: $font-weight-bold;
        }
        .desc {
            margin-bottom: 24px;
        }
        .result-link {
            font-weight: $font-weight-bold;
            color: #1B5D86;
            &::after {
                content: '';
                display: inline-block;
                background-image: url('../images/arrow-blue.svg');
                background-repeat: no-repeat;
                background-size: 16px;
                margin-left: 10px;
                width: 18px;
                height: 14px;
                margin-bottom: -2px;
            }
            &:hover {
                border-bottom: 1px solid #1B5D86;
                text-decoration: none;
            }
        }
    }
}

.clear-search {
    position: absolute;
    right: 5px;
    top: 0;
    padding: 27px;
    span {
        width: 2px;
        height: 24px;
        background-color: $black;
        display: block;
        transform: rotate(45deg);
        position: absolute;
        top: 16px;
        &:last-child {
            transform: rotate(135deg);
        }
    }
}

.header-container {
    &.mobile {
        .clear-search {
            right: 2px;
            padding: 25px;
            span {
                height: 20px;
            }
        }
    }
}