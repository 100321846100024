/* Colors */
$white: rgb(255,255,255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$gray: rgb(196, 196, 196); //#c4c4c4
$primary-color: rgb(0, 113, 235); //#0071eb
$secondary-color: rgb(59, 134, 150); //#3b8696
$secondary-color-light: rgb(169, 206, 222);//#a9d4de
$primary-color-dark: rgb(33, 75, 111); // #214b6f

/* Breakpoint Sizes */
$medium-width: 768px;
$large-width: 1024px;
$xlarge-width: 1250px;
$xxlarge-width: 1920px;

// Font Weights
$font-weight-norm: 400;
$font-weight-med: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;

// transition speeds
$trans-slow: 0.6s;
$trans-med: 0.4s;
$trans-fast: 0.2s;
