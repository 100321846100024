.link-box {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    text-decoration: none;
    padding: 32px;
    flex: 1;
    min-width: 300px;
    min-height: 246px;
    margin: 12px;
    background-color: $secondary-color;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.22);
    transition: box-shadow $trans-med ease;
    &.background-color--0061AA {
        background-color: #0061AA;
    }
    &.background-color--F5F6F7 {
        background-color: #F5F6F7;
        .link-box__title {
            color: $black;
        }
    }
    &.background-color--000000 {
        background-color: #000000;
    }
    &.background-color--5E6366 {
        background-color: #5E6366;
    }
    @include medium {
        min-width: auto;
    }
    &.full-width {
        max-width: none;
        .i72-svg {
            width: 70%;
            @include medium {
                width: 55%;
            }
            @include large {
                width: 45%;
            }
            @include xlarge {
                width: 33%;
                max-width: 500px;
            }
        }
    }
    .i72-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        position: absolute;
        inset: 0;
    }
    .i72-svg {
        position: absolute;
        width: 190px;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: width $trans-med ease;
    }
    &__overlay {
        position: absolute;
        z-index: 1;
        inset: 0;
        opacity: .5;
        transition: background-color $trans-med ease;
    }
    &__title {
        color: $white;
        z-index: 2;
        text-align: center;
        font-size: 2.25rem;
        line-height: 1.06;
        font-weight: $font-weight-semi;
    }
    &:hover, &:focus {
        box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.44);
        .overlay {
            background-color: rgba(155, 174, 181, .85);
        }
    }
}
