@media print {
    // sets print margin
    @page {
        margin: 1in;
    }  
    .footer-form,
    .button,
    .left-arrow,
    .right-arrow,
    .prof-pic,
    .col-svg,
    .overlay,
    .background-element,
    .site-main .sign-up-container.bg-dark,
    .site-main .sign-up-container.bg-light,
    .swipe-to-scroll,
    .bio-link,
    .sign-up-container
     {
        display: none !important;
    }
    body, html {
        overflow: visible !important;
    }
    * {
        color: $black !important;
    }
    .carousel-callout {
        background-color: #fff !important;
        .slider {
            width: 100% !important;
            .slides {
                width: auto !important;
                margin-left: 0 !important;
                white-space: normal !important;
                height: auto !important;
                .slide {
                    visibility: visible !important;
                    margin-bottom: 48px !important;
                    height: auto !important;
                    max-width: 100% !important;
                    margin: 0 auto 40px !important;
                    .slide-content {
                        max-width: none !important;
                        width: auto !important;
                    }
                }
            }
        }
    }
    .hero-callout {
        padding: 0 !important;
        min-height: auto !important;
        background-image: none !important;
    }
    .signup-flip-button {
        height: auto !important;
        background-color: #fff !important;
    }
    .multi-col-callout {
        .row {
            .col {
                display: block !important;
                break-inside: avoid !important;
                background-color: #fff !important;
                .col-header {
                    background-color: #fff !important;
                }
            }
        }
    }
    .get-involved-callout .involved-container .events .event {
        min-height: auto !important;
        break-inside: avoid !important;
    }
    .get-involved-callout .involved-container .events .event .date {
        background-color: #fff !important;
    }
    .get-involved-callout .involved-container .ctas .cta .header {
        background-color: #fff !important;
        .header-image {
            display: none !important;
        }
    }
    .get-involved-callout .involved-container .ctas .cta {
        break-inside: avoid !important;
        a {
            margin-bottom: 24 !important;
        }
    }
    .get-involved-callout .involved-container .ctas,
    .get-involved-callout .involved-container .events {
        float: none !important;
        width: 100% !important;
    }
}

