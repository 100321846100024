.search-bar-container {
    display: flex;
    background-color: #9baeb5;
    justify-content: center;
    align-items: center;
    height: 0;
    overflow: hidden;
    transition: height $trans-med ease, visibility $trans-med ease;
    form {
        width: 90%;
        margin: 0 auto;
        @include medium {
            width: 85%;
            max-width: 1000px;
        }
        input[type="text"] {
            background-color: $white;
            border-radius: 20px;
            border: 0;
            font-size: 1rem;
            height: 40px;
            width: 100%;
            padding: 12px 140px 12px 16px;
            transition: border-color $trans-med ease;
            @include medium {
                padding: 12px 140px 12px 32px;
            }
        }
        input[type="submit"] {
            z-index: 2;
            position: absolute;
            cursor: pointer;
            right: 3px;
            top: 3px;
            width: 128px;
            border-radius: 17px;
            height: 34px;
            border: 0;
            background-color: $secondary-color;
            color: $white;
            font-weight: $font-weight-semi;
            background-image: url('../images/arrow.svg');
            background-position: 90% center;
            background-size: 11px;
            background-repeat: no-repeat;
            transition: background-color $trans-med ease;
            &:hover {
                background-color: $primary-color-dark;
            }
        }
    }
    &.active {
        visibility: visible;
        z-index: 999;
        height: 135px;
    }
}

.search-results-container {
    max-height: 0;
    overflow: hidden;
    transition: all $trans-med ease;
    padding: 0 24px;
    @include medium {
        padding: 0 40px;
    }
    @include xlarge {
        padding: 0 60px;
    }
    &.active {
        padding: 48px 24px;
        max-height: 3000px;
        @include medium {
            padding: 48px 40px;
        }
        @include xlarge {
            padding: 54px 60px;
        }
    }
    .result-count {
        font-weight: $font-weight-bold;
        font-size: 1rem;
        padding-bottom: 16px;
        max-width: 1088px;
        display: block;
        border-bottom: solid 1px #d6d6d6;
        margin: 0 auto;
    }
    .result {
        padding: 42px 0;
        border-bottom: solid 1px #d6d6d6;
        max-width: 1088px;
        margin: 0 auto;
        .title {
            font-size: 1.3125rem;
            margin-bottom: 16px;
        }
        .desc {
            line-height: 1.75;
            margin-bottom: 16px;
        }
        a {
            color: $primary-color;
            font-size: 1rem;
            font-weight: $font-weight-bold;
            svg {
                width: 14px;
                margin-left: 6px;
                transition: margin-left $trans-med ease;
            }
            &:hover {
                text-decoration: underline;
                svg {
                    margin-left: 30px;
                }
            }
        }
    }
    .pagination-container {
        display: flex;
        justify-content: center;
        max-width: 1088px;
        padding-top: 16px;
        margin: 0 auto;
        @include xlarge {
            padding-top: 32px;
        }
        .prev-results, .next-results {
            color: $primary-color;
            font-weight: $font-weight-bold;
            font-size: 1rem;
            padding: 16px 0;
            svg {
                width: 8px;
                top: 1px;
                path {
                    fill: $primary-color;
                }
            }
            &:hover {
                text-decoration: underline;
            }
        }
        .next-results {
            margin-left: auto;
            svg {
                transform: rotate(270deg);
            }
        }
        .prev-results {
            margin-right: auto;
            svg {
                transform: rotate(90deg);
            }
        }
    }
    .close-search-results {
        position: absolute;
        right: 0;
        top: 15px;
        z-index: 11;
        background-color: $primary-color;
        color: #fff;
        text-decoration: none;
        height: 45px;
        font-size: 1rem;
        width: 94px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color $trans-med ease;
        &:hover {
            background-color: $primary-color-dark;
        }
    }
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px 6px;
    padding: 32px 16px 12px;
    &__button {
        font-size: 1.25rem;
        line-height: 1.2;
        text-decoration: none;
        margin: 0;
        padding: 11px 15px;
        color: $primary-color;
        transition: color $trans-med ease;
        span {
            svg {
                width: 15px;
                height: 15px;
                transform: rotate(270deg);
                path {
                    fill: $primary-color;
                    transition: fill $trans-med ease;
                }
            }
            &.prev {
                svg {
                    transform: rotate(90deg);
                }
            }
        }
        &:hover {
            color: $primary-color-dark;
            span {
                svg {
                    path {
                        fill: $primary-color-dark
                    }
                }
            }
        }
    }
    &__current {
        font-size: 1.25rem;
        line-height: 1.2;
        margin: 0;
        padding: 11px 15px;
    }
}