.footer {
    padding: 25px;
    background-image: linear-gradient(#c4ecf2, #fff);
    @include medium {
        padding: 30px 50px;
    }
    @include large {
        padding: 45px 80px;
    }
    &__container {
        max-width: 1450px;
        margin: 0 auto;
    }
    &__top-container {
        display: flex;
        flex-direction: column;
        @include medium {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between
        }
        + hr {
            margin: 12px 0;
            @include medium {
                margin: 20px 0;
            }
        }
    }
    &__location-info {
        order: 3;
        @include medium {
            order: 2;
            flex-basis: 50%;
        }
        @include large {
            order: 1;
            flex-basis: auto;
        }
    }
    &__ctas {
        order: 1;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        .button {
            margin: 0 6px;
            @include medium {
                margin: 0 16px;
            }
        }
        @include medium {
            flex-basis: 100%;
        }
        @include large {
            order: 2;
            flex-basis: auto;
            margin: 0;
            align-items: center;
        }
    }
    &__social-links {
        order: 2;
        display: flex;
        justify-content: center;
        margin-bottom: 16px;
        @include medium {
            order: 3;
            flex-basis: 50%;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 0;
        }
        @include large {
            flex-basis: auto;
        }
        a {
            margin: 0 6px;
            svg {
                width: 50px;
            }
        }
    }
    &__bottom-container {
        display: flex;
        flex-direction: column;
        @include medium {
            flex-direction: row;
            justify-content: space-between;
        }
    }
    &__copyright-text {
        margin-bottom: 12px;
        @include medium {
            margin-bottom: 0;
        }
    }
    &__misc-links {
        list-style: none;
        li {
            margin-bottom: 6px;
            @include medium {
                margin-bottom: 0;
                display: inline-block;
                margin-left: 16px;
            }
        }
    }
}