.contact-container {
    margin-top: 16px;
    @include large {
        display: flex;
        justify-content: space-between;
        padding-bottom: 48px;
        min-height: 525px;
    }
    &__left {
        margin-bottom: 40px;
        @include large {
            flex-basis: 50%;
            margin-bottom: 0;
        }
    }
    &__name {
        padding-left: 30px;
        line-height: 1.5;
        margin: 0;
    }
    &__info-container {
        margin-bottom: 32px;
        @include medium {
            display: flex;
            margin-bottom: 48px;
        }
    }
    &__address {
        background-image: url('../images/map-blue.svg');
        background-position: 0 0;
        line-height: 1.5;
        background-size: 18px;
        background-repeat: no-repeat;
        font-size: 1rem;
        padding-left: 30px;
        margin-bottom: 24px;
        @include medium {
            margin-bottom: 0;
            margin-right: 80px;
        }
    }
    &__info-info {
        margin-bottom: 24px;
        @include medium {
            margin-bottom: 0;
        }
        p {
            font-size: 1rem;
            line-height: 1.5;
            background-position: 0 0;
            background-size: contain;
            background-repeat: no-repeat;
            padding-left: 32px;
            margin-bottom: 16px;
            span {
                margin-right: 2px;
            }
            a {
                color: $primary-color;
                font-weight: $font-weight-semi;
                text-decoration: underline;
            }
        }
    }
    &__phone {
        background-image: url('../images/phone-blue.svg');
    }
    &__fax {
        background-image: url('../images/fax-blue.svg');
    }
    &__email {
        background-image: url('../images/email-blue.svg');
    }
    form {
        max-width: 500px;
        .nf-form-fields-required {
            display: none;
        }
        .nf-form-content  {
            padding: 0;
            .nf-field-container {
                margin-bottom: 0;
            }
        }
        input, textarea {
            width: 100%;
            color: $black !important;
            font-size: 1.125rem !important;
            height: 48px !important;
            padding: 0 18px;
            border: solid 2px $secondary-color !important;
            margin-bottom: 12px !important;
            transition: all $trans-med ease;
            &:focus {
                background-color: #e5ebef;
                border-color: #afc0c7;
            }
        }
        textarea {
            height: 120px !important;
            width: 100% !important;
            max-width: 500px !important;
            margin-bottom: 0;
        }
        input[type="button"] {
            min-height: 50px;
            cursor: pointer;
            text-decoration: none;
            font-size: 1.25rem;
            font-weight: $font-weight-bold;
            text-align: center;
            padding: 12px 26px;
            width: auto;
            min-width: auto;
            background-color: $primary-color;
            color: $white !important;
            margin-right: 0;
            margin-bottom: 0;
            border: 0 !important;
            margin-left: auto;
            display: block;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.16);
            transition: background-color $trans-med ease;
            &:hover {
                background-color: $primary-color-dark;
            }
        }
    }
    &__map {
        @include large {
            flex-basis: 48%;
        }
        iframe {
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, .16);
            max-width: 650px;
            width: 100% !important;
            @include large {
                height: 100% !important;
            }
        }
    }
}