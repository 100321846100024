.site-main {
    .sign-up-container {
        padding: 32px 12px;
        @include medium {
            padding: 32px 40px 60px;
        }
        @include xlarge {
            padding: 32px 60px 80px;
        }
        .sign-up-callout {
            height: 340px;
            transform-style: preserve-3d;
            -webkit-transform-style: preserve-3d;
            max-width: 450px;
            margin: 0 auto;
            border-radius: 20px;
            transition: height $trans-med ease;
            @media (min-width: 360px) {
                height: 325px;
            }
            @include medium {
                height: 290px;
                max-width: 986px;
            }
            @include xlarge {
                height: 270px;
            }
            .front, .back {
                position: absolute;
            }
            .front {
                transform: rotateX(0deg) translateZ(0px);
                -webkit-transform: rotateX(0deg) translateZ(0px);
                -moz-transform: rotateX(0deg) translateZ(0px);
                transform-origin: 50% 50%;
                -webkit-transform-origin: 50% 50%;
                -moz-transform-origin: 50% 50%;
                transition: all $trans-med ease, visibility 0s ease;
                -webkit-transition: all $trans-med ease, visibility 0s ease;
                -moz-transition: all $trans-med ease, visibility 0s ease;
                width: 100%;
                border-radius: 20px;
                box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
                min-height: auto;
                display: block;
                padding: 30px 35px 95px;
                background-color: $red;
                max-width: 986px;
                margin: 0 auto;
                height: 100%;
                @include medium {
                    padding: 60px 70px 75px;
                }
                @include xlarge {
                    padding: 48px 60px 65px;
                }
                h3 {
                    color: $white;
                    font-size: 2rem;
                    margin-bottom: 20px;
                    font-family: minerva-modern,sans-serif;
                    @media (min-width: 360px) {
                        font-size: 2.5rem;
                    }
                    @include medium {
                        font-size: 2.5rem;
                    }
                    @include xlarge {
                        font-size: 2.5rem;
                    }
                }
                p {
                    color: $white;
                    font-size: 1.5rem;
                }
                .multi-col-link {
                    background-image: url('../images/skinny-arrow.svg');
                    background-position: 85% center;
                    background-repeat: no-repeat;
                    background-size: 21px;
                    background-color: $white;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 150px;
                    height: 55px;
                    line-height: 55px;
                    border-top-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    transition: background-color $trans-med ease;
                    font-size: 1.1875rem;
                    color: $orange;
                    padding: 0 20px;
                    font-weight: $font-weight-bold;
                    letter-spacing: 1px;
                    @include medium {
                        background-size: 22px;
                        width: 150px;
                        height: 68px;
                        line-height: 68px;
                    }
                }
                &:hover, &:focus {
                    background-color: $red-dark;
                    box-shadow: 0px 0px 20px rgba(0, 0, 0, .33);
                }
            }
            .back {
                transform: rotateX(-179.5deg) translateZ(1px);
                -webkit-transform: rotateX(-179.5deg) translateZ(1px);
                -moz-transform: rotateX(-179.5deg) translateZ(1px);
                transform-origin: 50% 50%;
                -webkit-transform-origin: 50% 50%;
                -moz-transform-origin: 50% 50%;
                transition: all $trans-med ease, visibility 0s ease;
                -webkit-transition: all $trans-med ease, visibility 0s ease;
                -moz-transition: all $trans-med ease, visibility 0s ease;
                border-radius: 20px;
                width: 100%;
                height: 100%;
                visibility: hidden;
                padding: 45px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $red;
                @media (min-width: 360px) {
                    padding: 55px 10px;
                }
                @include medium {
                    padding: 60px 0 0;
                }
                @include xlarge {
                    padding: 40px 0 0;
                }
                .ngp-form {
                    div.content.thankYou {
                        width: 100%;
                        text-align: left;
                        .contributions {
                            width: 90%;
                            margin: 0 auto;
                            p {
                                font-family: lato,sans-serif;
                                color: $white;
                                font-size: 1.125rem;
                                line-height: 1.6;
                                a {
                                    font-family: inherit;
                                    color: $white;
                                    &:hover, &:focus {
                                        font-family: inherit;
                                        color: $tan-dark;
                                    }
                                }
                            }
                        }
                    }
                    section.at-inner {
                        background-color: transparent;
                        @include medium {
                            padding: 0 20px;
                        }
                        header.at-title {
                            color: $white;
                            font-family: lato,sans-serif;
                        }
                        header.at-markup h2 {
                            color: $white;
                            font-family: minerva-modern,sans-serif;
                            line-height: 1.3;
                        }
                        form {
                           fieldset.at-fieldset {
                                legend.at-legend {
                                    color: $white;
                                    font-family: lato,sans-serif;
                                }
                                div.at-fields {
                                    background-color: transparent;
                                    div.at-row {
                                        label.at-text, label.at-select, label {
                                            color: $white;
                                            font-family: lato,sans-serif;
                                            input[type="text"], input[type="tel"], select, input[type="email"] {
                                                border-color: #eae4d7;
                                                border-width: 2px;
                                                padding: 8px 16px;
                                                font-size: 1.25rem;
                                                color: #484848;
                                                font-family: lato,sans-serif;
                                                border-radius: 8px;
                                                height: 48px;
                                                &:focus, &:active {
                                                    border-color: $orange;
                                                }
                                            }
                                            small {
                                                color: $white;
                                                font-family: inherit;
                                            }
                                        }
                                        label.at-check {
                                            input[type=checkbox]:checked+span:before {
                                                background-color: $orange;
                                            }
                                            input[type=checkbox]:hover+span:before {
                                                border-color: $white;
                                                box-shadow: 0 0 4px 0 $orange;
                                            }
                                            input[type=checkbox]:focus+span:before {
                                                box-shadow: 0 0 4px 0 $orange;
                                            }
                                            span.at-checkbox-title {
                                                color: $white;
                                                font-family: lato,sans-serif;
                                            }
                                        }
                                        .updateMyProfileSection {
                                            span.text {
                                                color: $white;
                                                font-family: lato,sans-serif;
                                                b, i, strong {
                                                    font-family: inherit;
                                                    color: $white;
                                                }
                                            }
                                            input[type=checkbox]:checked+span:before {
                                                background-color: $orange;
                                            }
                                            input[type=checkbox]:hover+span:before {
                                                border-color: $white;
                                                box-shadow: 0 0 4px 0 $orange;
                                            }
                                            input[type=checkbox]:focus+span:before {
                                                box-shadow: 0 0 4px 0 $orange;
                                            }
                                        }
                                    }
                                }
                            } 
                            .at-form-submit .at-submit.btn-at.btn-at-primary {
                                display: inline-block;
                                width: auto;
                                font-size: 1.1875rem;
                                text-decoration: none;
                                font-weight: 700;
                                text-transform: uppercase;
                                background-color: $orange;
                                color: $white;
                                border: 0;
                                box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
                                padding: 16px 60px 16px 30px;
                                border-radius: 10px;
                                letter-spacing: 1px;
                                line-height: 1;
                                background-image: url('../images/arrow.svg');
                                background-repeat: no-repeat;
                                background-position: calc(100% - 30px) 49%;
                                text-shadow: none;
                                font-family: lato,sans-serif;
                                transition: background-color $trans-med ease;
                                &:hover {
                                    background-color: $orange-dark;
                                }
                            }
                        }
                    }
                }
            }
            &.active {
                height: 1015px;
                @include medium {
                    height: 750px;
                }
                @include xlarge {
                    height: 750px;
                }
                .front {
                    transform: rotateX(179.5deg);
                    -webkit-transform: rotateX(179.5deg);
                    -moz-transform: rotateX(179.5deg);
                    visibility: hidden;
                }
                .back {
                    transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
                    -webkit-transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
                    -moz-transform: rotateX(0deg) rotateY(0deg) translateZ(1px);
                    background-color: $red;
                    visibility: visible;
                }
            }
        }
        &.bg-light {
            background-color: $tan-light;
        }
        &.bg-dark {
            background-color: $tan-dark;
        }
    }
}

