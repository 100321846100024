.modal {
    position: fixed;
    inset: 0;
    z-index: 9999999;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, .5);
    &__container {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 9999999;
        transform: translate(-50%, -50%);
        background-color: #fff;
        width: 90%;
        max-width: 950px;
        border-radius: 10px;
        box-shadow: 0 3px 20px rgba(0,0,0,.16);
        max-height: 85vh;
        overflow: auto;
    }
    &__top {
        display: flex;
        flex-direction: row;
        padding: 8px;
        justify-content: flex-end;
    }
    &__button-close {
        font-weight: $font-weight-bold;
        padding: 4px 8px;
        span {
            display: inline-block;
            margin-left: 5px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    &__bottom {
        .ngp-form.at {
            header.at-title {
                font-family: minerva-modern, sans-serif !important;

            }
            header {
                > h2 {
                    font-size: 2rem;
                    line-height: normal;
                }
            }
            .content.thankYou {
                p {
                    font-family: lato, sans-serif;
                    color: #484848;
                    @include xlarge {
                        font-size: 1.125rem;
                    }
                }
                .contributions {
                    padding: 24px;
                }
            }
            form {
                label.at-text input[type=text], label.at-text textarea, label.at-select select, 
                .select2-container--default .select2-selection--multiple .select2-selection__rendered .select2-selection__choice, 
                label select.ticket-quantity-select, label select.ticket-quantity-select:focus,
                label.at-text input[type=tel], label.at-text input[type=email] {
                    border-color: #eae4d7 !important;
                    border-width: 2px;
                    padding: 8px 16px 10px;
                    font-size: 1.375rem;
                    color: #484848;
                    font-family: lato, sans-serif;
                    border-radius: 8px;
                    height: 52px;
                }
                label input[type=checkbox]:checked+span:before {
                    background-color: $orange;
                }
                fieldset legend {
                    color: #484848;
                    font-family: lato, sans-serif;
                    font-size: 1.5rem;
                    margin-bottom: 16px;
                    font-weight: 400;
                }
                .at-form-submit .at-submit.btn-at.btn-at-primary {
                    display: inline-block;
                    font-size: 1.1875rem;
                    text-decoration: none;
                    font-weight: 700;
                    text-transform: uppercase;
                    background-color: $orange;
                    text-shadow: none;
                    color: $white;
                    border: 0;
                    box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
                    padding: 16px 60px 16px 30px;
                    border-radius: 10px;
                    letter-spacing: 1px;
                    line-height: 1;
                    background-image: url('../images/arrow.svg');
                    background-repeat: no-repeat;
                    background-position: calc(100% - 30px) 49%;
                    transition: background-color $trans-med ease;
                    &:hover, &:focus {
                        background-color: $red;
                    }
                }
            }
        }
    }
}