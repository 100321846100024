.search-bar-container {
    display: none;
    @include medium {
        display: flex;
        background-color: $tan;
        justify-content: center;
        align-items: center;
        height: 0;
        overflow: hidden;
        transition: height $trans-med ease, visibility $trans-med ease;
    }
    .close-search-bar, .close-search-results {
        position: absolute;
        top: 24px;
        right: 50px;
        font-weight: $font-weight-bold;
        &:hover {
            text-decoration: underline;
        }
    }
    form {
        @include medium {
            width: 630px;
        }
        input[type="text"] {
            background-color: $white;
            border-radius: 12px;
            border: 2px solid $tan-dark;
            font-size: 1.25rem;
            height: 55px;
            width: 100%;
            padding: 12px 190px 12px 32px;
            transition: border-color $trans-med ease;
            &:focus {
                border-color: $orange;
                outline: 0;
            }
        }
        > font {
            position: absolute;
            top: 0;
            right: 0;
        }
        input[type="submit"] {
            z-index: 2;
            position: absolute;
            right: 2px;
            top: 2px;
            height: auto;
            background-color: $orange;
            border-radius: 10px;
            width: auto;
            background-image: url('../images/arrow.svg');
            background-repeat: no-repeat;
            background-size: 20px;
            background-position: calc(100% - 30px) 49%;
            transition: background-color $trans-med ease;
            &:hover {
                background-color: $red
            }
        }
    }
    &.active {
        @include medium {
            visibility: visible;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            z-index: 999;
            height: 190px;
        }
        @include xlarge {
            height: 162px;
        }
    }
}
header {
    z-index: 99;
    .home-link {
        display: inline-block;
        height: 74px;
        margin: 16px;
        width: 156px;
        margin-left: 6vw;
        @include medium {
            margin-left: 4vw;
        }
        @include xlarge {
            height: 126px;
            width: 272px;
            margin: 0;
            float: left;
            top: -10px;
        }
        .logo {
            height: 100%;
            width: 100%;
            margin-left: -8px;
        }
        &.mobile {
            @include xlarge {
                display: none;
            }
        }
        &.desktop {
            display: none;
            @include xlarge {
                display: inline-block;
            }
        }
    }
    .newsletter-button {
        @include xlarge {
            margin-left: 4px;
        }
    }
    .search-toggle {
        padding: 40px 34px 28px 34px;
        margin-right: 8px;
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px;
        span {
            background-image: url('../images/search-icon.svg');
            background-size: 14px;
            background-position: right center;
            background-repeat: no-repeat;
            font-size: 1.25rem;
            padding-right: 24px;
        }
        &.active {
            background-color: $tan;
            span {
                border-bottom: 1px solid $black;
            }
        }
        &:hover {
            span {
                border-bottom: 1px solid $black;
            }
        }
        &.tablet {
            display: none;
            @include medium {
                position: absolute;
                right: 90px;
                top: 0px;
                display: inline-block;
            }
            @include xlarge {
                display: none;
            }
        }
    }
    .menu-toggle {
        position: absolute;
        top: 40px;
        right: 22px;
        z-index: 2;
        transition: margin-top $trans-med ease;
        @include medium {
            right: 46px;
        }
        @include xlarge {
            display: none;
        }   
        span {
            background-color: $black;
            width: 32px;
            height: 5px;
            margin-bottom: 6px;
            display: block;
            opacity: 1;
            transition: transform $trans-med ease, opacity $trans-fast ease, height $trans-med ease;
        }
        &.active {
            span {
                transform: rotate(45deg);
                margin-top: 10px;
                &:nth-child(2) {
                    opacity: 0;
                }
                &:last-child {
                    transform: rotate(135deg);
                    margin-top: -26px;
                }
            }
        }
    }
    .main-nav-container {
        position: absolute;
        z-index: 4;
        top: 92px;
        width: 87.5%;
        left: 6.25%;
        margin: 0 auto;
        box-shadow: 0 3px 20px rgba(0, 0, 0, .10);
        background-color: $white;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        max-height: 0;
        padding: 0 16px;
        overflow: hidden;
        max-height: 0;
        transition: max-height $trans-med ease, padding $trans-med ease;
        &.active {
            max-height: 5000px;
            padding: 24px 16px 16px;
            @include medium {
                padding: 0px 45px 16px 0;
            }
            @include xlarge {
                box-shadow: none;
                padding: 25px 25px 15px;
            }
        }
        @include medium {
            padding: 0 45px 0 0;
            width: 92%;
            left: 4%;
        }
        @include xlarge {
            max-height: none;
            box-shadow: none;
            padding: 25px 0px 15px;
            max-width: 1530px;
            overflow: visible;
            position: static;
            top: auto;
            left: auto;
        }
    }
    a.back-to-nav {
        display: none;
        color: $orange;
        font-size: 1.1875rem;
        margin: 20px 0 32px 4px;
        @include medium {
            margin: 32px 0 24px 44px;
        }
        &.active {
            display: table;
        }
        @include xlarge {
            display: none;
        }
        &::before {
            content: '';
            background-image: url('../images/arrow-orange.svg');
            background-position: 0 49%;
            background-repeat: no-repeat;
            transform: rotate(180deg);
            display: inline-block;
            width: 21px;
            height: 16px;
            margin-right: 12px;
        }
        &:hover {
            text-decoration: underline;
        }
    }
    .header-container {
        form {
            width: 252px;
            max-width: 100%;
            margin: 0 auto 16px;
            transition: width $trans-med ease;
            &:focus-within {
                width: 90%;
            }
            @include medium {
                display: none;
            }
            input[type="text"] {
                font-size: 1.25rem;
                border: 2px solid $tan;
                border-radius: 10px;
                background-color: $white;
                color: $black;
                padding: 9px 70px 9px 20px;
                width: 100%;
                font-family: lato, sans-serif;
                font-style: italic;
                transition: border-color $trans-med ease;
                &:focus, &:active {
                    border-color: $orange;
                    outline: 0;
                }
            }
            input[type="submit"] {
                position: absolute;
                z-index: 2;
                right: 2px;
                top: 2px;
                padding: 13px 29px;
                height: 46px;
                background-position: center;
                border-radius: 8px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
        .social-links {
            list-style: none;
            text-align: center;
            margin: 16px 0 24px;
            li {
                display: inline-block;
                margin-right: 10px;
                vertical-align: top;
                a {
                    width: 36px;
                    height: 36px;
                    display: block;
                    border-radius: 50%;
                    background-color: $blue;
                    transition: background-color $trans-med ease, box-shadow $trans-med ease;
                    svg {
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                    &:hover, &:focus {
                        background-color: $blue-dark;
                        box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
                    }
                }
            }
        }
        &.desktop {
            display: none;
            @include xlarge {
                display: inline-block;
                text-align: right;
                margin-bottom: 32px;
                padding-right: 0px;
                float: right;
                min-width: 750px;
            }
            .social-links {
                @include xlarge {
                    display: inline-block;
                    margin: 0 0 0 12px;
                    vertical-align: middle;
                }
            }
        }
        &.mobile {
            display: block;
            text-align: center;
            @include medium {
                padding-left: 45px;
            }
            @include xlarge {
                display: none;
            }
            &.hide {
                display: none;
            }
            > .button {
                margin: 10px 6px 0;
                @include medium {
                    margin: 8px 6px 0;
                }
            }
        }
    }
    .main-nav {
        z-index: 4;
        @include medium {
            margin-bottom: 60px;
        }
        &.third-level {
            margin-bottom: 12px;
            #menu-main-navigation.active {
                width: 100%;
                margin-left: 0;
                padding-bottom: 0;
            }
        }
        #menu-main-navigation {
            list-style: none;
            @include medium {
                position: static;
                padding-bottom: 0;
                width: 100%;
                margin-left: 0;
                transition: width $trans-fast ease, margin-left $trans-fast ease, padding-bottom $trans-fast ease;
            }
            @include xlarge {
                text-align: right;
                float: right;
                padding-bottom: 0;
                padding-right: 20px;
                width: auto;
            }
            &.active {
                @include medium {
                    width: 55%;
                    margin-left: 45%;
                    padding-bottom: 275px;
                    transition: width $trans-fast ease, margin-left $trans-fast ease, padding-bottom 0s ease;
                }
                @include xlarge {
                    width: auto;
                    margin-left: 0;
                    padding-bottom: 0;
                }
            }
            > li {
                border-bottom: 1px solid $tan;
                &.hide {
                    border-bottom: 0;
                }
                &:first-child {
                    @include medium {
                        padding-top: 8px;
                    }
                    @include xlarge {
                        padding-top: 0;
                    }
                }
                &:nth-child(2) {
                    > a {
                        &.active, &:hover {
                            color: $red;
                        }
                    }
                    .header-cta {
                        background-color: $red;
                        &::after {
                            top: 70px;
                            border-left: 55px solid $red;
                            @include xlarge {
                                top: 40px;
                            }
                        }
                    }
                    .sub-menu {
                        .feat-event {
                            background-color: $red;
                            &:hover, &:focus {
                                background-color: $red-dark;
                            }
                        }
                    }
                }
                &:nth-child(3) {
                    > a {
                        &.active, &:hover {
                            color: $orange;
                        }
                    }
                    .header-cta {
                        background-color: $tan;
                        h3, .cta-content p {
                            color: $black;
                        }
                        .button {
                            color: $black;
                            &::after {
                                background-image: url('../images/small-arrow-black.svg');
                            }
                        }
                        &::after {
                            top: 140px;
                            border-left: 55px solid $tan;
                            @include xlarge {
                                top: 40px;
                            }
                        }
                    }
                    .sub-menu {
                        .feat-event {
                            background-color: $tan;
                            .feat-event-header, .feat-event-content p {
                                color: $black;
                            }
                            &:hover, &:focus {
                                background-color: $tan-dark;
                            }
                        }
                    }
                }
                &:nth-child(4) {
                    > a {
                        &.active, &:hover {
                            color: $blue;
                        }
                    }
                    .header-cta {
                        background-color: $blue;
                        &::after {
                            top: 210px;
                            border-left: 55px solid $blue;
                            @include xlarge {
                                top: 40px;
                            }
                        }
                    }
                    .sub-menu {
                        .feat-event {
                            background-color: $blue;
                            &:hover, &:focus {
                                background-color: $blue-dark;
                            }
                        }
                    }
                }
                @include medium {
                    position: static;
                    margin-left: 45px;
                }
                @include xlarge {
                    margin-left: 0;
                    display: inline-block;
                    vertical-align: top;
                    border-bottom: none;
                }
                > a {
                    font-family: minerva-modern, sans-serif;
                    padding: 18px 12px;
                    font-size: 1.25rem;
                    display: block;
                    background-color: $white;
                    @include medium {
                        padding: 18px 24px;
                        font-size: 1.5rem;
                        z-index: 3;
                    }
                    @include xlarge {
                        padding: 4px 8px;
                        font-size: 1.25rem;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        right: 20px;
                        transform: translateY(-50%);
                        background-image: url('../images/caret.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        height: 20px;
                        width: 20px;
                        transition: transform $trans-med ease, top $trans-med ease;
                        @include medium {
                            right: 32px;
                        }
                        @include xlarge {
                            content: none;
                        }
                    }
                    &::before {
                        display: block;
                        content: attr(title);
                        font-weight: $font-weight-bold;
                        height: 1px;
                        color: transparent;
                        overflow: hidden;
                        visibility: hidden;
                    }
                    &.active {
                        color: $green;
                        font-weight: 600;
                        &::after {
                            transform: rotate(180deg);
                            top: calc(50% - 10px);
                        }
                    }
                    &:hover {
                        color: $green;
                        font-weight: 600;
                    }
                    &.hide {
                        display: none;
                    }
                }
                .header-cta {
                    background-color: $green;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 12px;
                    border-radius: 5px;
                    box-shadow: 0 3px 20px rgba(0, 0, 0, .10);
                    text-align: center;
                    min-height: 107px;
                    @include medium {
                        display: block;
                        opacity: 0;
                        border-radius: 0;
                        text-align: left;
                        position: absolute;
                        width: 40%;
                        height: 0;
                        left: 0;
                        top: 0;
                        padding: 0 40px;
                        z-index: 0;
                        transition: height $trans-med ease, padding $trans-med ease;
                        overflow: hidden;
                    }
                    @include xlarge {
                        display: block;
                        opacity: 1;
                        max-width: 30%;
                        height: 100%;
                    }
                    h3 {
                        font-size: 1.5rem;
                        color: $white;
                        font-weight: $font-weight-norm;
                        width: 75%;
                        margin: 0 auto 6px;
                        @include medium {
                            font-size: 2rem;
                            width: auto;
                            margin-bottom: 12px;
                        }
                        @include xlarge {
                            font-size: 3rem;
                            line-height: 1;
                        }
                    }
                    .cta-content {
                        display: none;
                        @include medium {
                            display: block;
                            color: $white;
                            margin-bottom: 20px;
                        }
                        p {
                            color: $white;
                            display: block;
                            display: -webkit-box;
                            -webkit-line-clamp: 15;
                            max-height: 21em;
                            line-height: 1.4;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            visibility: visible;
                            @include xlarge {
                                -webkit-line-clamp: 10;
                                max-height: 14em;
                            }
                        }
                    }
                    .button {
                        font-size: 1rem;
                        background-color: transparent;
                        box-shadow: none;
                        padding: 0;
                        background-position: 100%;
                        background-image: none;
                        @include medium {
                            font-size: 1.125rem;
                        }
                        &:hover {
                            text-decoration: underline;
                        }
                        &::after {
                            content: '';
                            display: inline-block;
                            background-image: url('../images/small-arrow.svg');
                            width: 20px;
                            height: 12px;
                            margin-left: 10px;
                            background-position: center;
                            background-repeat: no-repeat;
                        }
                    }
                    &::after {
                        @include medium {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: -55px;
                            width: 0; 
                            height: 0; 
                            border-top: 35px solid transparent;
                            border-bottom: 35px solid transparent;
                            border-left: 55px solid $green;
                        }
                        @include xlarge {
                            top: 40px;
                        }
                    }
                    &.hide {
                        display: none !important;
                    }
                }
                .sub-menu {
                    max-height: 0;
                    overflow: hidden;
                    visibility: hidden;
                    list-style: none;
                    position: static;
                    transition: max-height $trans-med ease, padding-top $trans-med ease, min-height $trans-med ease, visibility $trans-med ease;
                    @include medium {
                        transition: max-height $trans-fast ease, padding-top $trans-fast ease, min-height $trans-fast ease, visibility $trans-fast ease;
                    }
                    @include xlarge {
                        position: absolute;
                        min-height: 0;
                        margin-top: 4px;
                        width: 1350px;
                        max-width: 100%;
                        right: 0;
                        z-index: 2;
                        padding-top: 0;
                        background-color: $white;
                        border-bottom-right-radius: 20px;
                        border-bottom-left-radius: 20px;
                        box-shadow: 0 3px 20px rgba(0, 0, 0, .10);
                    }
                    &.active {
                        max-height: 1500px;
                        overflow: visible;
                        visibility: visible;
                        @include xlarge {
                            padding-top: 42px;
                            overflow: hidden;
                            min-height: 350px;
                        }
                        .header-cta {
                            @include medium {
                                display: block;
                                opacity: 1;
                                padding: 48px 40px;
                                height: 100%;
                                overflow: visible;
                            }
                            @include xlarge {
                                padding-top: 46px;
                            }
                        }
                        .feat-event {
                            @include medium {
                                display: block;
                                padding: 24px 28px 32px;
                                height: 205px;
                                min-height: 205px;
                                position: absolute;
                                bottom: 0;
                                z-index: 3;
                                right: 0;
                                width: calc(55% - 45px);
                            }
                            @include xlarge {
                                width: 326px;
                                min-height: 205px;
                                text-align: left;
                                position: absolute;
                                bottom: auto;
                                top: 46px;
                                right: 40px;
                                z-index: 3;
                            }
                        }
                    }
                    > li {
                        @include xlarge {
                            text-align: left;
                            margin-left: 40%;
                            margin-bottom: 40px;
                            max-width: 25%;
                        }
                        > a {
                            text-transform: uppercase;
                            padding: 18px 50px 18px 12px;
                            display: block;
                            background-color: $white;
                            @include medium {
                                font-size: 1.25rem;
                                padding-left: 24px;
                                z-index: 3;
                            }
                            @include xlarge {
                                font-size: 1.125rem;
                                padding: 4px;
                                font-weight: $font-weight-semi;
                                letter-spacing: 2px;
                            }
                            &.hide {
                                display: none;
                            }
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                        &.menu-item-has-children {
                            > a {
                                background-image: url('../images/arrow-orange.svg');
                                background-position: calc(100% - 25px) 49%;
                                background-repeat: no-repeat;
                                @include xlarge {
                                    background-image: none;
                                }
                                &:hover {
                                    text-decoration: underline;
                                }
                                &.enabled {
                                    background-image: none;
                                    padding: 8px 50px 8px 12px;
                                }
                            }
                            .sub-menu {
                                max-height: 0;
                                overflow: hidden;
                                @include xlarge {
                                    position: relative;
                                    max-height: none;
                                    box-shadow: none;
                                    border-radius: 0;
                                    visibility: unset;
                                }
                                &.third-level {
                                    max-height: none;
                                    visibility: visible;
                                    li {
                                        a {
                                            display: block !important;
                                            padding: 8px 50px 8px 12px;
                                            &:hover {
                                                text-decoration: underline;
                                            }
                                        }
                                    }
                                }
                                > li {
                                    margin-left: 0;
                                    margin-bottom: 0;
                                    max-width: none;
                                    a {
                                        outline-offset: -1px;
                                        @include xlarge {
                                            font-size: 1rem;
                                            padding: 4px 4px 4px 20px;
                                            text-transform: none;
                                            font-weight: $font-weight-norm;
                                            letter-spacing: normal;
                                        }
                                        &:hover {
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .feat-event {
                    display: none;
                    @include medium {
                        display: none;
                        box-shadow: 0 3px 20px rgba(0, 0, 0, .10);
                        background-color: $green;
                        padding: 0 28px;
                        border-top-left-radius: 20px;
                        border-bottom-right-radius: 20px;
                        height: 0;
                        transition: height $trans-med ease, padding $trans-med ease, background-color $trans-med ease, box-shadow $trans-med ease;
                    }
                    @include xlarge {
                        display: block;
                        width: 326px;
                        height: 205px;
                        text-align: left;
                        position: absolute;
                        top: 25px;
                        right: 40px;
                        z-index: 3;
                    }
                    .feat-event-header {
                        color: $white;
                        font-size: 2rem;
                        font-weight: $font-weight-norm;
                        margin-bottom: 10px;
                        font-family: minerva-modern, sans-serif;
                    }
                    .feat-event-content {
                        p {
                            color: $white;
                            display: block;
                            display: -webkit-box;
                            -webkit-line-clamp: 4;
                            max-height: 5.2em;
                            line-height: 1.3;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            visibility: visible;
                        }
                    }
                    &::after {
                        content: '';
                        z-index: 2;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        width: 46px;
                        height: 46px;
                        background-color: $white;
                        border-top-left-radius: 20px;
                        border-bottom-right-radius: 20px;
                        background-image: url('../images/arrow-orange.svg');
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                    &.hide {
                        display: none !important;
                    }
                    &:hover, &:focus {
                        background-color: $green-dark;
                        box-shadow: 0 0 20px rgba(0, 0, 0, .50);
                    }
                }
                &:last-child {
                    margin-bottom: 32px;
                    @include medium {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}