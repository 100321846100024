.card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border: 1px solid $gray;
    margin-bottom: 32px;
    overflow: hidden;
    flex: 1;
    @include medium {
        margin-bottom: 0;
        border-radius: 8px;
        max-width: 1014px;
        margin: 0;
        min-width: 47%;
        width: 47%;
    }
    @include large {
        min-width: 30%;
        width: auto;
    }
    &.full-width {
        max-width: none;
        width: 100%;
    }
    &.align-center {
        .card__inner-container {
            text-align: center;
        }
    }
    &.align-right {
        .card__inner-container {
            text-align: right;
        }
    }
    .i72-image, .i72-svg {
        max-height: 400px;
        height: 50vw;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include medium {
            height: 45vw;
        }
        svg {
            height: 100%;
            width: 100%;
        }
    }
    &__inner-container {
        padding: 30px;
        color: $black;
        @include medium {
            padding: 24px;
        }
        @include large {
            padding: 30px;
        }
    }
    &__title {
        margin-bottom: 20px;
        font-size: 2rem;
        color: $black;
    }
    &__text {
        margin-bottom: 20px;
        p {
            color: $black;
        }
    }
    &.card--anchor {
        text-decoration: none;
        > a {
            display: block;
            position: absolute;
            inset: 0;
            z-index: 2;
            outline-offset: -2px;
        }
    }
}