.alerts {
    background-color: #a06347;
    padding: 32px;
    @include medium {
        padding: 45px 90px 32px; 
    }
    @include xlarge {
        padding: 48px 146px;
    }
    .alert {
        max-width: 965px;
        p {
            color: $white;
            line-height: 1.5;
            font-size: 1.25rem;
        }
    }
}