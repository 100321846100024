.paragraph {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    flex: 1;
    @include medium {
        margin-bottom: 20px;
        flex-direction: row;
    }
    @include large {
        margin-bottom: 32px;
    }
    &.align-center {
        @include medium {
            flex-direction: column;
        }
        .i72-image, .i72-svg {
            margin: 0 auto 24px;
        }
        .paragraph__inner-container {
            text-align: center;
            align-items: center;
        }
    }
    &.align-left {
        .paragraph__inner-container {
            text-align: left;
            align-items: flex-start;
        }
    }
    &.align-right {
        .i72-image, .i72-svg {
            @include medium {
                order: 2;
                margin: 0 0 0 32px;
            }
            @include xlarge {
                margin-left: 48px;
            }
        }
        .paragraph__inner-container {
            text-align: right;
            align-items: flex-end;
        }
    }
    &.paragraph--icon {
        min-height: 200px;
        min-width: 100vw;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px 20px 6px;
        @include medium {
            align-items: flex-start;
            justify-content: flex-start;
            min-height: 250px;
            min-width: 305px;
            padding: 24px;
            padding: 10px 16px 12px;
        }
        @include large {
            min-height: 250px;
            padding: 0;
            min-width: calc(33% - 40px);
            padding: 10px 24px 12px;
        }
        @include xlarge {
            min-width: calc(33% - 60px);
            padding: 10px 32px 12px;
        }
        @include xxlarge {
            min-width: calc(33% - 80px);
        }
        &.align-center {
            .i72-image, .i72-svg {
                margin: 0 auto;
            }
        }
        &.align-left {
            .i72-image, .i72-svg {
                margin-right: auto;
            }
        }
        &.align-right {
            .i72-image, .i72-svg {
                margin-left: auto;
            }
        }
        .i72-svg {
            width: 60px;
            max-height: 60px;
            height: auto;
            margin-bottom: 16px;
            min-width: auto;
            max-width: none;
            @include large {
                width: 68px;
                max-height: 68px;
                margin-bottom: 20px;
            }
            @include xlarge {
                width: 72px;
                max-height: 72px;
                margin-bottom: 24px;
            }
            @include xxlarge {
                width: 85px;
                max-height: 85px;
                margin-bottom: 32px;
            }
            svg {
                path, circle {
                    fill: $secondary-color-light;
                }
            }
        }
        .paragraph__inner-container {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            @include medium {
                max-width: none;
                padding: 0 !important;
            }
        }
        .paragraph__title {
            font-size: 1rem;
            letter-spacing: 2.52px;
            margin-bottom: 20px;
            text-transform: uppercase;
            @include large {
                font-size: 1.5rem;
                line-height: 1;
                letter-spacing: 3px;
            }
            @include xlarge {
                font-size: 1.75rem;
                letter-spacing: 4px;
                margin-bottom: 8px;
                line-height: 1.2;
            }
            @include xxlarge {
                font-size: 2rem;
                margin-bottom: 10px;
                letter-spacing: 5.76px;
            }
        } 
        .paragraph__text {
            max-width: none;
            font-size: .875rem;
            line-height: 1.5;
            @include large {
                font-size: 1rem;
            }
            @include xlarge {
                font-size: 1.125rem;
            }
        }
    }
    &.paragraph--cta {
        .i72-svg {
            max-height: 100px;
            height: auto;
            width: 75%;
            max-width: 275px;
            @include large {
                max-height: 150px;
            }
        }
        .paragraph__title {
            font-weight: $font-weight-norm;
            @include xxlarge {
                font-size: 3.25rem;
            }
        }
        .paragraph__button {
            margin-top: 12px;
        }
        .paragraph__inner-container {
            max-width: none;
            @include medium {
                max-width: none;
            }
        }
        &.align-center {
            align-items: center;
            .i72-svg {
                @include medium {
                    margin: 0 0 20px;
                }
            }
            @include medium {
                flex-direction: column;
            }
            .paragraph__inner-container {
                @include medium {
                    margin: 0 auto;
                    align-items: center;
                    max-width: 650px;
                }
            }
        }
        &.align-left {
            align-items: flex-start;
            @include medium {
                flex-direction: column;
                justify-content: flex-start;
            }
            .i72-svg {
                @include medium {
                    margin: 0 32px 0 0;
                }
            }
            .paragraph__inner-container {
                align-items: flex-start;
                @include medium {
                    margin: 0 auto 0 0;
                }
                @include large {
                    max-width: 950px;
                }
            }
        }
        &.align-right {
            align-items: flex-end;
            @include medium {
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
            }
            .i72-svg {
                @include medium {
                    margin: 0 0 0 32px;
                    order: initial;
                }
            }
            .i72-image {
                @include medium {
                    order: initial;
                }
            }
            .paragraph__inner-container {
                align-items: flex-end;
                @include medium {
                    margin: 0 0 0 auto;
                }
                @include large {
                    max-width: 950px;
                }
            }
            .paragraph__text {
                @include medium {
                    text-align: right;
                }
            }
        }
    }
    .i72-image, .i72-svg {
        max-height: 250px;
        height: 50vw;
        width: 100%;
        margin-bottom: 16px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @include medium {
            height: 300px;
            width: 300px;
            margin: 0 32px 0 0;
        }
        @include xlarge {
            margin-right: 48px;
        }
        svg {
            height: 100%;
            width: 100%;
        }
    }
    &__inner-container {
        color: $black;
        flex: 1;
        @include medium {
            padding: 24px 0;
        }
        @include large {
            padding: 30px 0;
        }
        @include xlarge {
            padding: 30px 0;
        }
    }
    &__title {
        margin-bottom: 20px;
        font-size: 2rem;
    }
    &__text {
        //
    }
    &__link {
        margin-top: 20px;
        color: $primary-color;
        @include large {
            font-size: 1.125rem;
        }
    }
    &.paragraph--anchor {
        text-decoration: none;
        > a {
            display: block;
            position: absolute;
            inset: 0;
            z-index: 2;
            outline-offset: -2px;
        }
    }
}

.row {
    .paragraph {
        @include medium {
            flex-direction: column;
        }
        .i72-image, .i72-svg {
            @include medium {
                height: 30vw;
                width: 100%;
                margin: 0 0 16px;
            }
        }
        &.align-right {
            .i72-image, .i72-svg {
                @include medium {
                    order: unset;
                }
            }
            .paragraph__inner-container {
                text-align: right;
                align-items: flex-end;
            }
        }
        &.paragraph--icon {
            &.align-left {
                .i72-image, .i72-svg {
                    margin-right: auto;
                }
            }
            &.align-right {
                .i72-image, .i72-svg {
                    margin-left: auto;
                }
            }
            .i72-svg {
                width: 60px;
                max-height: 60px;
                height: auto;
                margin-bottom: 16px;
                min-width: auto;
                max-width: none;
                @include large {
                    width: 68px;
                    max-height: 68px;
                    margin-bottom: 20px;
                }
                @include xlarge {
                    width: 72px;
                    max-height: 72px;
                    margin-bottom: 24px;
                }
                @include xxlarge {
                    width: 85px;
                    max-height: 85px;
                    margin-bottom: 32px;
                }
                svg {
                    path, circle {
                        fill: $secondary-color-light;
                    }
                }
            }
        }
        &.paragraph--cta {
            .i72-svg, .i72-image {
                @include medium {
                    height: 22vw;
                    max-height: 250px;
                }
            }
        }
    }
}