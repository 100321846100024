.archive-people, .archive-trustees {
    .archive-tabs {
        max-width: 1700px;
        margin: 0 auto 48px;
        @include medium {
            margin-bottom: 30px;
            padding: 0 8px;
        }
        @include xlarge {
            margin-bottom: 12px;
        }
        .tabs-labels {
            list-style-type: none;
            text-align: center;
            white-space: nowrap;
            overflow-x: scroll;
            padding: 15px 0;
            margin-bottom: 20px;
            scroll-snap-type: x mandatory;
            li {
                display: inline-block;
                scroll-snap-align: center;
                a {
                    font-family: minerva-modern, sans-serif;
                    display: block;
                    font-size: 1.5rem;
                    font-weight: 400;
                    padding: 30px 30px 27px;
                    text-transform: uppercase;
                    transition: background-color $trans-med ease, color $trans-med ease;
                    @include xlarge {
                        font-size: 1.75rem;
                        padding: 40px 48px 37px;
                    }
                    &:hover {
                        color: $blue;
                        background-color: rgba(34, 104, 147, .04);
                    }
                    &.active {
                        color: $blue;
                        background-color: rgba(34, 104, 147, .04);
                        border-bottom: 3px solid $blue;
                        padding-bottom: 24px;
                        @include xlarge {
                            border-bottom: 4px solid $blue;
                            padding-bottom: 33px;
                        }
                    }
                }
            }
        }
        .swipe-to-scroll {
            color: $blue;
            &::before, &::after {
                background-image: url('../images/arrow-blue.svg');
            }
            @include large {
                display: none;
            }
            &.disable {
                display: none;
            }
        }
        .tabs-contents {
            .tab-content {
                display: none;
                list-style-type: none;
                overflow: hidden;
                margin: 0 auto;
                text-align: center;
                &.active {
                    display: block;
                    padding: 24px 0 0;
                    @include large {
                        padding: 32px 0 0;
                    }
                    @include xlarge {
                        padding-top: 54px;
                    }
                }
                .archive-item {
                    text-align: center;
                    background-color: $tan-light;
                    width: 100%;
                    max-width: 374px;
                    min-height: 566px;
                    padding: 40px 12px 36px;
                    margin: 0 auto 20px;
                    transition: background-color $trans-med ease;
                    @include medium {
                        display: inline-block;
                        width: 372px;
                        height: 566px;
                        margin-bottom: 38px;
                    }
                    @include xlarge {
                        width: 374px;
                        margin-bottom: 82px;
                    }
                    .post-icon {
                        background-size: cover;
                        background-position: center;
                        width: 303px;
                        height: 303px;
                        max-width: 100%;
                        border-radius: 15px;
                        margin: 0 auto 30px;
                    }
                    .archive-bottom {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        height: 160px;
                        gap: 6px 0;
                        .post-title {
                            font-size: 1.625rem;
                            color: $black;
                        }
                        .archive-bottom-content, .job-title {
                            text-transform: uppercase;
                            font-weight: $font-weight-bold;
                            max-width: 75%;
                            margin: 0 auto;
                        }
                        .position-title {
                            color: #1b5d86;
                        }
                        span {
                            font-size: 1.1875rem;
                            color: $black;
                            display: table;
                            margin: 0 auto;
                            padding-right: 30px;
                            background-image: url('../images/arrow-black.svg');
                            background-position: 100% center;
                            background-repeat: no-repeat;
                            background-size: 16px;
                            padding-bottom: 2px;
                        }
                    }
                    &:hover {
                        background-color: $tan;
                        span {
                            border-bottom: 1px solid $black;
                            padding-bottom: 1px;
                        }
                    }
                }
            }
        }
    }
}

.archive-trustees {
    .archive-tabs {
        .tabs-contents {
            .tab-content {
                .archive-item {
                    min-height: 530px;
                    @include medium {
                        height: 530px;
                    }
                    .post-icon {
                        background-image: url('../images/Placeholder.jpg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: cover;
                    }
                    .archive-bottom {
                        height: auto;
                        min-height: 105px;
                    }
                }
            }
        }
    }
}