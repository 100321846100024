.multi-col-callout {
    max-width: 1450px;
    margin: 0 auto 50px;
    padding: 0 15px;
    .heading {
        padding: 0 10px;
        margin-bottom: 16px;
    }
    .row {
        display: flex;
        flex-direction: column;
        @include medium {
            flex-direction: row;
        }
        .col {
            width: 100%;
            padding: 10px;
            .card {
                background-color: $white;
                border: 1px solid $gray;
                height: 100%;
                .col-image {
                    max-height: 250px;
                    height: 50vw;
                    width: 100%;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    @include medium {
                        height: 30vw;
                    }
                }
                .card-body {
                    padding: 30px;
                    @include medium {
                        padding: 24px;
                    }
                    @include large {
                        padding: 30px;
                    }
                    .col-heading {
                        margin-bottom: 20px;
                    }
                    .col-content {
                        margin-bottom: 20px;
                    }
                }
            }
            &[data-col-count="4"] {
                @include medium {
                    padding: 6px;
                    .card {
                        .card-body {
                            padding: 24px 16px;
                        }
                    }
                }
                @include large {
                    padding: 10px;
                    .card {
                        .card-body {
                            padding: 30px;
                        }
                    }
                }
            }
        }
    }
}

.full-width-callout {
    max-width: 1450px;
    margin: 0 auto 50px;
    padding: 40px 25px;
    @include medium {
        padding: 50px;
    }
    .heading {
        margin-bottom: 16px;
    }
    .content {
        margin-bottom: 24px;
    }
}