.wp-block-group {
    margin: 32px auto;
    @include medium {
        margin: 40px auto;
    }
    @include xlarge {
        max-width: 1640px;
    }
	.wp-block-columns {
	    margin: 0 auto;
	    padding: 12px;
	    @include medium {
	        padding: 0 40px;
	    }
	    @include large {
	        display: table;
	        table-layout: fixed;
	        width: 100%;
	        padding: 0 28px;
	    }
	    @include xlarge {
	        max-width: 1640px;
	        padding: 0 40px;
	    }
		.wp-block-columns {
		    margin: 0 auto;
		    padding: 0;
		    @include medium {
		        padding: 0;
		    }
		    @include large {
		        display: table;
		        table-layout: fixed;
		        width: 100%;
		        padding: 0;
		    }
		    @include xlarge {
		        max-width: 1640px;
		        padding: 0;
		    }
		}
	}
}

.post-content {
    .wp-block-columns {
        margin: 0 auto;
        padding: 24px 0px;
        @include medium {
            padding: 40px 0;
            gap: 40px;
        }
        @include large {
            width: 100%;
            padding: 28px 0;
        }
        @include xlarge {
            max-width: 1640px;
            padding: 60px 0;
            gap: 64px;
        }
        .wp-block-column {
            margin-bottom: 4px;
            vertical-align: top;
            padding: 0;
            @include medium {
                padding: 12px 0;
            }
            @include large {
                width: 100%;
                padding: 12px 0;
                margin-bottom: 0;
            }
            @include xlarge {
                padding: 12px 0px;
            }
        }
    }
}

.wp-block-columns {
    margin: 0 auto;
    padding: 24px 12px;
    @include medium {
        padding: 40px;
        gap: 40px;
    }
    @include large {
        width: 100%;
        padding: 28px 40px;
    }
    @include xlarge {
        max-width: 1640px;
        padding: 60px;
        gap: 64px;
    }
	.wp-block-embed {
	    padding: 24px 0;
	    max-width: 1640px;
	    margin: 0 auto;
	    @include medium {
	        padding: 32px 0;
	    }
	    @include xlarge {
	        padding: 40px 0;
	    }
	}
    .wp-block-column {
        margin-bottom: 4px;
        vertical-align: top;
        padding: 0 12px;
        @include medium {
            padding: 12px 0;
        }
        @include large {
            width: 100%;
            padding: 12px 0;
            margin-bottom: 0;
        }
        @include xlarge {
            padding: 12px 0px;
        }
		.wysiwyg-callout {
		    padding: 0;
		    max-width: 1640px;
		    margin: 0 auto;
		    @include medium {
		        padding: 12px 0;
		    }
		    @include xlarge {
		        padding: 12px 0px;
		    }
		}
        p {
            margin-bottom: 20px;
            @include medium {
                font-size: 1.125rem;
            }
        }
        ul, ol {
            padding-left: 20px;
            li {
                margin-bottom: 8px;
                line-height: 1.25;
                @include medium {
                    font-size: 1.125rem;
                }
            }
        }
        h1, h2 {
            margin-bottom: 24px;
        }
        h3 {
            margin-bottom: 18px;
        }
        h4, h5, h6 {
            margin-bottom: 12px;
        }
        a {
            color: $blue;
            text-decoration: underline;
        }
        .wp-block-button__link {
            color: $white;
            text-decoration: none;
        }
        .wp-block-image {
            padding: 0;
            margin: 0 auto;
            @include medium {
                padding: 0;
            }
            @include xlarge {
                padding: 0;
            }
            figure {
                display: block;
                &.aligncenter {
                    text-align: center;
                }
                &.alignleft {
                    text-align: left;
                    float: none;
                }
                &.alignright {
                    text-align: right;
                    float: none;
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .wp-block-buttons {
            padding: 0;
            margin: 0 auto;
            @include medium {
                padding: 12px 0;
            }
            @include xlarge {
                padding: 12px 0;
            }
        }
        &.is-vertically-aligned-center {
            vertical-align: middle;
        }
    }
}

.wp-block-group {
    h1, h2, h3, h4, h5, h6, p {
        margin-bottom: 16px;
    }
}

.wp-block-image {
    padding: 24px 12px;
    max-width: 1640px;
    margin: 0 auto;
    @include medium {
        padding: 24px 40px;
    }
    @include xlarge {
        padding: 24px 60px;
    }
    figure {
        display: block;
        &.aligncenter {
            text-align: center;
            display: block;
        }
        &.alignleft {
            text-align: left;
            float: none;
            display: block;
        }
        &.alignright {
            text-align: right;
            float: none;
            display: block;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    img {
        max-width: 100%;
        height: auto;
    }
}

.has-text-align-center {
    text-align: center;
}

.has-text-align-right {
    text-align: right;
}

.has-text-align-left {
    text-align: left;
}

.wp-block-embed {
    padding: 24px 12px;
    max-width: 1640px;
    margin: 0 auto;
    @include medium {
        padding: 40px;
    }
    @include xlarge {
        padding: 60px;
    }
    &.aligncenter {
        text-align: center;
    }
    &.alignleft {
        text-align: left;
    }
    &.alignright {
        text-align: right;
    }
    iframe {
        max-width: 100%;
    }
}

.wp-block-buttons {
    padding: 12px;
    max-width: 1640px;
    margin: 0 auto;
    @include medium {
        padding: 12px 40px;
    }
    @include xlarge {
        padding: 12px 60px;
    }
    .wp-block-button {
        margin-bottom: 16px;
    }
    .wp-block-button__link {
        display: inline-block;
		text-align: left;
        font-size: 1.1875rem;
        text-decoration: none;
        font-weight: 700;
        text-transform: uppercase;
        background-color: $orange;
        color: $white;
        border: 0;
        box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
        padding: 16px 60px 16px 30px;
        border-radius: 10px;
        letter-spacing: 1px;
        line-height: 1;
        background-image: url('../images/arrow.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 30px) 49%;
        transition: background-color $trans-med ease;
        &:hover, &:focus {
            background-color: $red;
        }
    }
    &.is-content-justification-center {
        text-align: center;
    }
    &.is-content-justification-right {
        text-align: right;
    }
    &.is-content-justification-left {
        text-align: left;
    }
}

.wp-block-social-links {
    padding: 12px;
    max-width: 1640px;
    margin: 0 auto;
    @include medium {
        padding: 12px 40px;
    }
    @include xlarge {
        padding: 12px 60px;
    }
    .wp-social-link {
        display: inline-block;
        margin-right: 10px;
        a {
            display: inline-block;
            vertical-align: top;
            padding: 10px;
            line-height: 0;
            border-radius: 50%;
            background-color: $blue;
            transition: background-color $trans-med ease, box-shadow $trans-med ease;
            svg {
                path {
                    fill: $white;
                }
            }
            &:hover, &:focus {
                background-color: $blue-dark;
                box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
            }
        }
        &.wp-social-link-facebook {
            a {
                background-image: url('../images/facebook.svg');
                background-repeat: no-repeat;
                background-size: 12px;
                background-position: center;
                padding: 0;
                height: 44px;
                width: 44px;
                svg {
                    display: none;
                }
            }
        }
    }
    &.aligncenter {
        text-align: center;
    }
    &.alignleft {
        text-align: left;
    }
    &.alignright {
        text-align: right;
    }
}
.wp-caption {
    &.alignleft {
        float: left;
        margin-right:20px;
    }
    &.alignright {
        float: right;
        margin-left:20px;
    }
}