/* ignition72 Base */
*,
* * {
	margin:0;
	padding:0;
	position:relative;
	box-sizing: border-box;
}

/*	ensure HTML5 elements are handled as block level on older browsers */
footer,
header,
nav,
main,
section {
	display: block;
}

*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

* {
	font-family: lato, sans-serif;
}

html, body {
    height: 100%;
    width: 100%;
}

body {
    font-family: lato, sans-serif;
    color: $black;
}

.page-container {
    padding: 0;
}

.clear {
    clear: both;
}

button, .button {
    display: inline-block;
    font-size: 1.1875rem;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;
    background-color: $orange;
    color: $white;
    border: 0;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
    padding: 16px 60px 16px 30px;
    border-radius: 10px;
    letter-spacing: 1px;
    line-height: 1;
    background-image: url('../images/arrow.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 30px) 49%;
    transition: background-color $trans-med ease;
    &.blue {
        background-color: $blue;
        &:hover, &:focus {
            background-color: $blue-dark;
        }
    }
    &.red {
        background-color: $red;
        &:hover, &:focus {
            background-color: $orange;
        }
    }
    &.green {
        background-color: $green;
        &:hover, &:focus {
            background-color: $green-dark;
        }
    }
    &.off-white {
        background-image: url('../images/arrow-black.svg');
        background-color: $tan-light;
        color: $black;
        &:hover, &:focus {
            background-color: $tan;
        }
    }
    &.gray-light {
        background-image: url('../images/arrow-black.svg');
        background-color: $tan;
        color: $black;
        &:hover, &:focus {
            background-color: $tan-dark;
        }
    }
    &:hover, &:focus {
        background-color: $red;
    }
}

a {
    text-decoration: none;
    color: $black;
    &:focus {
        outline: -webkit-focus-ring-color auto 1px !important;
    }
}

.container {
    max-width: 1520px;
    margin: 0 auto;
}

p {
    font-size: 1rem;
    color: $black;
    line-height: 1.5;
}

h1 {
    font-family: minerva-modern, sans-serif !important;
    font-size: 1.75rem;
    font-weight: 400;
    @include medium {
        font-size: 2.625rem;
    }
    @include xlarge {
        font-size: 3.25rem;
    }
    b, em {
        font-family: minerva-modern, sans-serif !important;
    }
}

h2 {
    font-family: minerva-modern, sans-serif !important;
    font-size: 2rem;
    font-weight: 400;
    @include medium {
        font-size: 2.375rem;
    }
    @include xlarge {
        font-size: 3rem;
    }
    b, em {
        font-family: minerva-modern, sans-serif !important;
    }
}

h3 {
    font-family: minerva-modern, sans-serif !important;
    font-size: 1.75rem;
    font-weight: 400;
    @include medium {
        font-size: 2rem;
    }
    @include xlarge {
        font-size: 2.25rem;
    }
    b, em {
        font-family: minerva-modern, sans-serif !important;
    }
}

h4 {
    font-family: minerva-modern, sans-serif !important;
    font-size: 1.625rem;
    font-weight: 400;
    @include medium {
        font-size: 1.875rem;
    }
    @include xlarge {
        font-size: 2.125rem;
    }
    b, em {
        font-family: minerva-modern, sans-serif !important;
    }
}

h5 {
    font-family: minerva-modern, sans-serif !important;
    font-size: 1.5rem;
    font-weight: 400;
    @include medium {
        font-size: 1.625rem;
    }
    @include xlarge {
        font-size: 2rem;
    }
    b, em {
        font-family: minerva-modern, sans-serif !important;
    }
}

h6 {
    font-family: minerva-modern, sans-serif !important;
    font-size: 1.33rem;
    font-weight: 400;
    @include medium {
        font-size: 1.5rem;
    }
    @include xlarge {
        font-size: 1.875rem;
    }
    b, em {
        font-family: minerva-modern, sans-serif !important;
    }
}

.swipe-to-scroll {
    padding: 0 32px;
    text-align: center;
    font-size: 1.125rem;
    &::before, &::after {
        content: '';
        display: inline-block;
        background-image: url('../images/arrow-black.svg');
        background-repeat: no-repeat;
        transform: rotate(180deg);
        background-position: center;
        background-size: 16px;
        margin-right: 14px;
        width: 20px;
        height: 13px;
    }
    &::after {
        transform: rotate(0deg);
        margin-left: 14px;
    }
}

.skip-nav {
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
	&:focus {
        background-color: $orange;
		left: 10px;
		top: 5px;
	    width: auto;
	    height: auto;
	    z-index: 9999999;
	}
}

.post-content-title {
    padding: 0 40px;
    margin: 32px auto 20px;
    @include xlarge {
        padding: 0 60px;
        max-width: 1640px;
        margin-bottom: 32px;
    }
}
.post-content {
    padding: 0 40px 50px;
    @include xlarge {
        padding: 0 60px 75px;
        max-width: 1640px;
        margin: 0 auto;
    }
    hr {
        margin-bottom: 16px;
    }
    h2, h3, h4, h5, h6 {
        margin-bottom: 10px;
        @include xlarge {
            margin-bottom: 16px;
        }
    }
    p {
        margin-bottom: 24px;
        @include medium {
            font-size: 1.125rem;
        }
    }
    .resource-author {
        font-style: italic;
    }
    ul, ol {
        margin-bottom: 24px;
        list-style-position: inside;
        text-indent: -26px;
        padding-left: 25px;
        @include medium {
            font-size: 1.125rem;
        }
        li {
            margin-bottom: 8px;
        }
    }
    a {
        color: $blue;
        text-decoration: underline;
    }
    .resource-img {
        margin-bottom: 20px;
        max-width: 600px;
        @include xlarge {
            margin-bottom: 32px;
        }
        img {
            max-width: 100%;
        }
    }
    .wp-block-image {
        padding: 24px 0;
        max-width: 1520px;
        margin: 0 auto;
        @include medium {
            padding: 24px 0;
        }
        @include xlarge {
            padding: 24px 0;
        }
    }
    img {
        max-width: 100%;
        &.alignleft {
            float: left;
            margin: 10px 20px;
            margin-left: 0;
        }
        &.alignright {
            float: right;
            margin: 10px 20px;
            margin-right: 0;
        }
        &.aligncenter {
            margin: 0px auto;
            display: block;
        }
    }
}

.hero-callout {
    + .post-content {
        @include medium {
            margin-top: -30px;
        }
    }
    + .wysiwyg-callout {
        margin-top: -20px;
        @include medium {
            margin-top: -40px;
        }
        @include xlarge {
            margin-top: -60px;
        }
    }
}

.wysiwyg-callout {
    padding: 24px 12px;
    max-width: 1640px;
    margin: 0 auto;
    @include medium {
        padding: 32px;
    }
    @include xlarge {
        padding: 32px 60px;
    }
    h2, h3, h4, h5, h6 {
        margin-bottom: 16px;
        @include xlarge {
            margin-bottom: 24px;
        }
    }
    a {
        color: $blue;
        text-decoration: underline;
    }
    p {
        margin-bottom: 16px;
        @include medium {
            font-size: 1.125rem;
        }
        @include xlarge {
            margin-bottom: 24px;
        }
    }
    p:empty {
        display: none;
    }
	img {
        max-width: 100%;
        &.alignleft {
            float: left;
            margin: 10px 40px 10px 0;
        }
        &.alignright {
            float: right;
            margin: 10px 0 10px 40px;
        }
        &.aligncenter {
            margin: 0px auto;
            display: block;
        }
    }
    ul, ol {
        padding-left: 20px;
        li {
            margin-bottom: 8px;
            line-height: 1.25;
            @include medium {
                font-size: 1.125rem;
            }
        }
    }
	&:after {
		content: ".";
	    visibility: hidden;
	    display: block;
	    height: 0;
	    clear: both;
	}
}

iframe {
    max-width: 100%;
}

.text-section {
    .content {
        a {
            text-decoration: underline;
            color: $blue-dark;
        }
    }
}