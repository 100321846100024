.full-width-callout {
    padding: 0 32px;
    margin-top: 80px;
    margin-bottom: 80px;
    max-width: none;
    background-color: $tan-light;
    @include medium {
        padding: 0 40px;
        margin-top: 80px;
    }
    @include xlarge {
        padding: 0 60px;
        margin-top: 110px;
        margin-bottom: 115px;
    }
    .full-width-container {
        max-width: 1520px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        @include medium {
            flex-direction: row;
        }
    }
    .text-section {
        order: 2;
        text-align: center;
        padding-bottom: 56px;
        margin: 0;
        margin-bottom: 0;
        max-width: none;
        @include medium {
            order: 1;
            text-align: left;
            margin-right: 30px;
            padding: 32px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: baseline;
            width: 50%;
        }
        @include xxlarge {
            padding: 30px 5%;
        }
        h2 {
            margin-bottom: 24px;
        }
        .content {
            margin-bottom: 32px;
        }
        .button {

        }
    }
    .image-section {
        order: 1;
        margin-bottom: 32px;
        margin-top: -48px;
        @include medium {
            width: 50%;
            order: 2;
            margin-bottom: -20px;
            margin-top: -20px;
            margin-left: 30px;
            display: flex;
        }
        @include xlarge {
            margin-bottom: -40px;
            margin-top: -40px;
        }
        .image-circle {
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 50%;
            width: 275px;
            height: 275px;
            margin: 0 auto;
            @include medium {
                width: 370px;
                height: 370px;
                align-self: center;
            }
            @include xlarge {
                width: 475px;
                height: 475px;
            }
        }
    }
}