.video {
    text-align: center;
    @include medium {
        margin: 0 24px;
        flex: 1;
    }
    iframe {
        max-width: 100%;
        height: auto;
        max-height: 360px;
        aspect-ratio: 16/9;
    }
}

.row {
    .video {
        iframe {
            margin-bottom: 24px;
        }
    }
}