footer {
    background-color: $tan-light;
    padding: 40px 12px 30px;
    display: inline-block;
    width: 100%;
    @include medium {
        padding: 36px 24px 30px 40px;
    }
    @include large {
        padding: 36px 32px 30px 40px;
    }
    .container {
        display: flex;
        flex-direction: column;
        @include medium {
            display: block;
            max-width: 1570px;
        }
        .content {
            padding: 0 40px;
            margin: 0 auto;
            @include medium {
                padding: 0;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
            @include large {
                align-items: stretch;
            }
            @include xlarge {
                padding: 0 20px;
                justify-content: space-between;
            }
            .logo {
                margin-left: -8px;
                margin-bottom: 20px;
                width: 245px;
                @include medium {
                    width: 225px;
                    margin-bottom: 18px;
                    display: inline-block;
                }
                @include large {
                    width: 225px;
                    margin-bottom: 0;
                }
            }
            .contact-info {
                margin-bottom: 18px;
                @include medium {
                    margin-bottom: 12px;
                }
                @include large {
                    margin-bottom: 0;
                }
            }
            .content-left {
                @include large {
                    display: flex;
                    gap: 0 80px;
                    flex-wrap: wrap;
                    flex-basis: 66%;
                }
                @include xlarge {
                    gap: initial;
                    flex-basis: 51%;
                    justify-content: space-between;
                }
                @media (min-width: 1500px) {
                    flex-basis: 47%;
                }
            }
            .content-right {
                max-width: 242px;
                @include medium {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    max-width: none;
                }
                @include xlarge {
                    flex-direction: row;
                    align-items: flex-start;
                    flex-basis: 43%;
                    justify-content: space-between;
                }
                .footer-bottom-box {
                    @include medium {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    @include xlarge {
                        max-width: 242px;
                        order: 1;
                    }
                    .gtranslate_wrapper {
                        @include medium {
                            width: 100%;
                        }
                    }
                }
            }
            .social-links {
                list-style: none;
                margin: 16px 0 20px;
                @include medium {
                    margin: 0 0 12px;
                }
                li {
                    display: inline-block;
                    margin-right: 10px;
                    vertical-align: top;
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        width: 40px;
                        height: 40px;
                        display: block;
                        border-radius: 50%;
                        background-color: $blue;
                        transition: background-color $trans-med ease, box-shadow $trans-med ease;
                        svg {
                            width: 17px;
                            height: 17px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }
                        &:hover, &:focus {
                            background-color: $blue-dark;
                            box-shadow: 0 3px 20px rgba(0, 0, 0, .16);
                        }
                    }
                }
            }
        }
        .logo-box {
            @include xlarge {
                display: flex;
            }
        }
        .guidestar {
            display: inline-block;
            margin-bottom: 20px;
            margin-right: 24px;
            height: 70px;
            @include medium {
                margin-bottom: 0;
                height: 90px;
            }
            @include xlarge {
                height: 100px;
            }
            img {
                height: 70px;
                width: auto;
                @include medium {
                    height: 90px;
                }
                @include xlarge {
                    height: 100px;
                }
            }
        }
        .charity-navigator {
            display: inline-block;
            height: 70px;
            @include medium {
                height: 90px;
            }
            @include xlarge {
                height: 100px;
            }
            img {
                width: auto;
                height: 70px;
                @include medium {
                    height: 90px;
                }
                @include xlarge {
                    height: 100px;
                }
            }
        }
        .footer-bottom-container {
            @include medium {
                flex-basis: 100%;
                display: flex;
                align-items: flex-end;
                justify-content: flex-end;
            }
            @include large {
                margin-top: 16px;
            }
        }
        .links {
            @include large {
                display: inline-block;
            }
            a {
                font-size: .875rem;
                color: $blue;
                display: table;
                text-decoration: underline;
                margin-bottom: 8px;
                font-weight: $font-weight-bold;
                @include medium {
                    display: inline-block;
                    margin-bottom: 0;
                    margin-right: 24px;
                    line-height: 1.575;
                }
                &:last-child {
                    @include medium {
                        margin-right: 0;
                    }
                }
            }
        }
        .copyright {
            font-size: .875rem;
            margin-bottom: 8px;
            @include medium {
                display: inline-block;
                margin-bottom: 0;
                margin-right: 24px;
            }
        }
        select.notranslate {
            margin-bottom: 20px;
            width: 100%;
            padding: 0 10px;
            height: 32px;
            border-radius: 4px;
            border-width: 2px;
            border-color: $tan;
            -moz-appearance: none; 
            -webkit-appearance: none; 
            appearance: none;
            background-image: url('../images/caret.svg');
            background-position: 93% 12px;
            background-repeat: no-repeat;
            background-size: 12px;
            line-height: 1.5;
            font-size: .875rem;
            padding-right: 24px;
            &:focus {
                outline-color: $orange;
            }
            @include medium {
                margin-bottom: 12px;
            }
        }
    }
}