.full-width-quote {
    margin-top: 80px;
    margin-bottom: 80px;
    background-color: $tan-light;
    &__container {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        @include large {
            flex-direction: row;
        }
    }
    &__quote-section {
        order: 2;
        text-align: center;
        padding-bottom: 56px;
        margin: 0;
        margin-bottom: 0;
        max-width: none;
        padding-top: 30px;
        @include large {
            order: 1;
            text-align: left;
            padding: 48px 0;
            padding-right: 30px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: baseline;
            width: 50%;
            padding-left: 40px;
        }
        @include xlarge {
            padding-right: 4vw;
        }
        @include xxlarge {
            padding: 48px 4vw 48px 5%;
        }
        &.blue {
            background-color: $blue;
        }
        &.red {
            background-color: $red;
        }
        &.green {
            background-color: $green;
        }
        &.yellow {
            background-color: $orange;
        }
        &.off-white {
            background-color: $tan-light;
            .full-width-quote__quote, .full-width-quote__author, .full-width-quote__title {
                color: $black;
            }
            .full-width-quote__line {
                background-color: $black;
            }
            >svg {
                path {
                    fill: $black
                }
            }
        }
        &.gray-light {
            background-color: $tan;
            .full-width-quote__quote, .full-width-quote__author, .full-width-quote__title {
                color: $black;
            }
            .full-width-quote__line {
                background-color: $black;
            }
            >svg {
                path {
                    fill: $black
                }
            }
        }
        > svg {
            @include large {
                width: 90px;
                height: auto;
                min-width: 90px;
            }
        }
    }
    &__quote-inner-container {
        padding-top: 32px;
        @include large {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
    &__quote-container {
        text-align: right;
        padding: 0 30px;
        margin-bottom: 30px;
    }
    &__line {
        height: 4px;
        width: 75%;
        margin-left: 25%;
        background-color: $white;;
        @include large {
            margin-left: 0;
            width: 100%;
            margin-right: -30px;
        }
        @include xlarge {
            width: calc(100% + 4vw - 30px);
            margin-right: -4vw;
        }
    }
    &__quote {
        font-size: 1.5rem;
        margin-bottom: 20px;
        padding: 0 30px;
        margin-bottom: 20px;
        color: $white;
        font-family: minerva-modern, sans-serif;
        @include medium {
            font-size: 1.75rem;
        }
        @include large {
            margin-bottom: 30px;
        }
        @include xlarge {
            font-size: 2.25rem;
        }
    }
    &__author {
        font-size: 1.75rem;
        font-family: minerva-modern, sans-serif;
        margin-bottom: 3px;
        color: $white;
        text-align: right;
    }
    &__title {
        font-size: 1.1875rem;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        color: $white;
        text-align: right;
    }
    &__button {
        margin-top: 30px;
        @include large {
            margin-right: auto;
            margin-left: 30px;
            margin-top: 40px;
        }
    }
    &__image-section {
        order: 1;
        margin-bottom: 32px;
        margin-top: -48px;
        @include large {
            width: 50%;
            order: 2;
            margin: 0;
            padding: 30px;
            display: flex;
        }
        @include xlarge {
            padding: 48px 5%;
        }
    }
    &__image {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
        width: 275px;
        height: 275px;
        margin: 0 auto;
        @include large {
            width: 370px;
            height: 370px;
            align-self: center;
        }
        @include xlarge {
            width: 475px;
            height: 475px;
        }
    }
}