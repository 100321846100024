/* Colors */
$white: rgb(255,255,255); // #ffffff
$black: rgb(72, 72, 72); // #484848
$tan-light: rgb(252, 250, 247); // #fcfaf7
$tan: rgb(234, 228, 215); // #eae4d7
$tan-dark: rgb(224, 217, 201); // #E0D9C9
$orange: rgb(196, 131, 46); // #c4832e
$orange-dark: rgb(166, 97, 23); // #A66217
$blue: rgb(34, 104, 147); // #226893
$blue-dark: rgb(20, 80, 117); // #145075
$green: rgb(96, 119, 106); // #60776a
$green-dark: rgb(66, 92, 78); // #425c4e
$red: rgb(126, 74, 72); // #7e4a48
$red-dark: rgb(116, 62, 60); // #743E3C


/* Breakpoint Sizes */
$medium-width: 768px;
$large-width: 1024px;
$xlarge-width: 1250px;
$xxlarge-width: 1920px;

// Font Weights
$font-weight-norm: 400;
$font-weight-med: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;

// transition speeds
$trans-slow: 0.6s;
$trans-med: 0.4s;
$trans-fast: 0.2s;
