.featured-fellow-callout {
    background-color: $green;
    padding: 45px 0 80px;
    text-align: center;
    @include xlarge {
        padding: 80px 0 100px;
    }
    h2 {
        color: $white;
        margin-bottom: 40px;
        padding: 0 40px;
        @include xlarge {
            margin-bottom: 65px;
        }
    }
    .featured-fellow-container {
        margin: 20px auto 0 auto;
        max-width: 1400px;
        .prof-pic {
            display: block;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            width: 180px;
            height: 180px;
            margin: 0 auto 30px;
            @include medium {
                display: inline-block;
                vertical-align: top;
                height: 230px;
                width: 230px;
                margin: 0 36px 0 0;
            }
            @include xlarge {
                height: 230px;
                width: 230px;
            }
        }
        .slide-content {
            display: block;
            width: 60%;
            margin: 0 auto;
            text-align: center;
            color: $white;
            @include medium {
                text-align: left;
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 510px);
            }
            @include xlarge {
                width: calc(100% - 550px);
                max-width: 850px;
            }
            .name, .role, .carousel-bio, .bio-link {
                color: $white;
            }
            .name {
                text-align: center;
                font-size: 1.625rem;
                margin-bottom: 12px;
                @include medium {
                    text-align: left;
                    font-size: 1.75rem;
                }
                @include xlarge {
                    font-size: 2rem;
                    margin-bottom: 8px;
                }
            }
            .role {
                font-size: 1rem;
                font-weight: $font-weight-semi;
                text-transform: uppercase;
                margin-bottom: 16px;
            }
            .carousel-bio {
                p {
                    font-size: 1rem;
                    color: $white;
                    margin-bottom: 24px;
                    text-align: left;
                    white-space: normal;
                    display: block;
                    display: -webkit-box;
                    -webkit-line-clamp: 6;
                    max-height: 7.8em;
                    line-height: 1.3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    visibility: visible;
                    @include medium {
                        max-height: 6.5em;
                        -webkit-line-clamp: 5;
                    }
                    @include xlarge {
                        margin-bottom: 20px;
                        font-size: 1.25rem;
                        max-height: 5.2em;
                        line-height: 1.3;
                        -webkit-line-clamp: 4;
                    }
                }
            }
            .bio-link {
                font-size: 1.125rem;
                font-weight: $font-weight-semi;
                text-transform: uppercase;
                background-position: 100% 5px;
                background-image: url('../images/small-arrow.svg');
                padding-right: 28px;
                display: inline-block;
                background-size: 18px;
                padding-bottom: 2px;
                background-repeat: no-repeat;
                &:hover, &:focus {
                    border-bottom: 2px solid;
                    padding-bottom: 0;
                }
            }
        }
    }
}